import React, { useState } from 'react';
import Box from '@mui/material/Box';
import GradientButton from "../../../components/Global/GradientButton/GradientButton";
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import CustomTextField from '../../Global/CustomTextField/CustomTextField';
import ShinyText from '../../Global/ShinyText/ShinyText';

const UpdateAccount = ({ image, name, city, profile }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [formData, setFormData] = useState({
    name: name || '',
    lastName: 'hola',
    address: '',
    phone: '',
    email: "email",
    password: "hola"
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    console.log("Valores guardados:", formData);
    // Aquí podrías enviar los datos al backend o realizar otras acciones
  };

  return (
    <Box
      sx={{
        background: colors.primary[400],
        padding: { xs: "20px", md: "30px" },
        width: "100%",
        borderRadius: "15px",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        marginBottom: "20px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <ShinyText text="Configuración de Cuenta" disabled={false} speed={3} margin='0px 0px 20px 0px' fontSize='25px' />
      </Box>
      <CustomTextField
        title="Nombre"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      <CustomTextField
        title="Apellido"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
      />
      <CustomTextField
        title="Dirección"
        name="address"
        value={formData.address}
        onChange={handleChange}
      />
      <CustomTextField
        title="Teléfono"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
      />
      <CustomTextField
        title="Correo"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      <CustomTextField
        title="Contraseña"
        name="password"
        value={formData.password}
        type="password"
        onChange={handleChange}
      />

      <GradientButton onClick={handleSubmit}>
        Guardar
      </GradientButton>
    </Box>
  );
};

export default UpdateAccount;
