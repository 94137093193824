import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { InputAdornment, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import SearchIcon from '@mui/icons-material/Search';

const Filter = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [activeButton, setActiveButton] = useState('Nº Pedido');
    const [searchPlaceholder, setSearchPlaceholder] = useState('Buscar por Nº Venta...');

    const buttons = [
        { label: 'Nº Pedido', placeholder: 'Buscar por Nº Pedido...' },
        { label: 'Sin Descargar', placeholder: 'Buscar por Nº Pedido...' },
        { label: 'Guia', placeholder: 'Buscar por Guia...' },
        { label: 'Amazon', placeholder: 'Buscar por Amazon...' },
    ];

    const handleButtonClick = (button) => {
        setActiveButton(button.label);
        setSearchPlaceholder(button.placeholder);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: "row" }, alignItems: 'center', justifyContent: "center", padding: '20px', borderRadius: '15px', backgroundColor: colors.primary[980] }}>
            <TextField
                variant="standard"
                placeholder={searchPlaceholder}
                sx={{ width: { xs: '100%', lg: "35%" }, marginBottom: { xs: '20px', lg: "0px" } }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: "row" }, justifyContent: 'space-around', width: { xs: '100%', lg: "65%" }, marginLeft: { xs: '0px', lg: "10px" } }}>
                {buttons.map((button) => (
                    <Button
                        key={button.label}
                        variant="contained"
                        color={activeButton === button.label ? 'primary' : 'secondary'}
                        onClick={() => handleButtonClick(button)}
                        sx={{ height: "30px", margin: '5px', background: activeButton === button.label ? "linear-gradient(to right, #0064FB, #1ECDF8)" : colors.primary[980], boxShadow: "none", borderRadius: "20px", "&:hover": { background: "none", border: "2px solid #1ECDF8" }, color: colors.primary[100] }}
                    >
                        {button.label}
                    </Button>
                ))}
            </Box>
        </Box>
    );
};

export default Filter;
