import { Box, IconButton, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { tokens } from "../../../theme";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const CustomTextField = ({ title, value, onChange, name, type = "text", ...props }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box sx={{ width: "100%", mb: "15px" }}>
      <Typography variant="h5" sx={{ fontFamily: 'Arial, sans-serif', marginBottom: "10px" }}>
        {title}
      </Typography>
      <TextField
        type={type === "password" && !showPassword ? "password" : "text"}
        value={value}
        onChange={onChange}
        name={name}
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: type === "password" && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            color: colors.primary[100],
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            padding: '0 14px',
            bgcolor: colors.primary[980],
            width: "100%",
            borderRadius: "10px"
          },
        }}
        sx={{ background: 'transparent', borderRadius: '10px' }}
        {...props}
      />
    </Box>
  );
};

export default CustomTextField;
