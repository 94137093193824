import Box from "@mui/material/Box";
import { Grid, TextField, Typography, Tabs, Tab, Autocomplete, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../../theme";
import { useState } from "react";
import ShinyText from "../../Global/ShinyText/ShinyText";
import GradientButton from "../../Global/GradientButton/GradientButton"
import CustomTextField from "../../Global/CustomTextField/CustomTextField";

const UpdateProducts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow:
          "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        bgcolor: colors.primary[400],
        borderRadius: "15px",
        padding: "15px",
        color: colors.primary[100]
      }}
    >
      <Box
        sx={{
          padding: { xs: "5px", md: "20px" },
          width: "95%",
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

        }}
      >
        <Box sx={{textAlign: "center"}}>
          <ShinyText text="Centro Especializado de Soporte" disabled={false} speed={3} fontSize="25px" />
        </Box>
        <Typography
          variant="h5"
          sx={{ my: "15px", textAlign: "center", color: colors.primary[100] }}
        >
          ¿Necesitas ayuda? Radica un ticket de soporte, en breve un agente de soporte resolverá todas tus inquietudes.
        </Typography>

        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor={"white"}
          sx={{ marginBottom: "20px" }}
        >
          <Tab label="Ayuda y soporte" sx={{ bgcolor: colors.primary[980], borderRadius: "10px", margin: "5px" }} />
          <Tab label="Buscar artículos" sx={{ bgcolor: colors.primary[980], borderRadius: "10px", margin: "5px" }} />
        </Tabs>

        <Box sx={{ width: "100%", minHeight: "400px", display: "flex", flexDirection: "column" }}>
          {activeTab === 0 && (
            <Grid container spacing={3} sx={{ flexGrow: 1 }}>
              <Grid item xs={12}>
                <CustomTextField
                  title="Solicitado Por"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  title="Asunto"
                />
              </Grid>

              <Grid item xs={12}>
                <CustomTextField
                  title="Descripción"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel style={{ color: "white" }}>Categoría</InputLabel>
                  <Select variant="outlined" defaultValue="" sx={{ width: '100%', display: "flex", flexDirection: "row", backgroundColor: colors.primary[980] }}
                    size="medium"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          backgroundColor: colors.primary[980],
                        },
                      },
                    }}>
                    <MenuItem value="Cliente demo">Cliente demo</MenuItem>
                    <MenuItem value="Incidencia técnica">Incidencia técnica</MenuItem>
                    <MenuItem value="Información sobre pagos">
                      Información sobre pagos
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <GradientButton gradient={"red"}>
                  Adjuntar archivo
                  <input type="file" hidden />
                </GradientButton>
              </Grid>

              <Grid item xs={12}>
                <GradientButton>
                  Enviar
                </GradientButton>
              </Grid>
            </Grid>
          )}

          {activeTab === 1 && (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Autocomplete
                options={[]}
                noOptionsText="Artículos no encontrados"
                sx={{ width: '100%', display: "flex", flexDirection: "row", backgroundColor: colors.primary[980] }}
                size="medium"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: colors.primary[980],
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Buscar..."
                    variant="outlined"
                    InputLabelProps={{ style: { color: colors.primary[100] } }}
                    InputProps={{ ...params.InputProps, style: { color: colors.primary[100] } }}
                  />
                )}
              />
            </Box>
          )}
        </Box>

      </Box>
    </Box>
  );
};

export default UpdateProducts;
