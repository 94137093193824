import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../../theme';
import { useState } from 'react';
import ShinyText from '../../Global/ShinyText/ShinyText';
import CustomTextField from '../../Global/CustomTextField/CustomTextField';
import GradientButton from '../../Global/GradientButton/GradientButton';
import CustomDatagrid from '../../Global/CustomDatagrid/CustomDatagrid';

const NationalTaxesSettings = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = (row) => {
        setSelectedRow(row);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedRow(null);
    };

    const handleSaveRow = () => {
        if (selectedRow.id != null) {
            setRows(rows.map(row => (row.id === selectedRow.id ? selectedRow : row)));
        } else {
            setRows([...rows, { ...selectedRow, id: Date.now() }]);
        }
        handleCloseDialog();
    };

    const handleDeleteRow = () => {
        setRows(rows.filter(row => row.id !== selectedRow.id));
        handleCloseDialog();
    };

    const handleCellEdit = (params) => {
        handleOpenDialog(params.row);
    };

    const columns = [
        { field: 'min', headerName: 'Rango Mínimo', flex: 1, editable: false },
        { field: 'max', headerName: 'Rango Máximo', flex: 1, editable: false },
        { field: 'importValue', headerName: 'Valor USD', flex: 1, editable: false }
    ];

    return (
        <Box
            sx={{
                background: colors.primary[400],
                padding: { xs: '12px', md: '30px' },
                width: '100%',
                borderRadius: '15px',
                boxShadow: 'rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                color: colors.primary[100],
                marginBottom: '5px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Box sx={{ textAlign: "center" }}>
                <ShinyText text="Impuesto Nacional" disabled={false} speed={3} margin="0px 0px 20px 0px" fontSize="25px" />
            </Box>
            <GradientButton
                onClick={() => handleOpenDialog({ min: '', max: '', importValue: '' })}
            >
                Agregar Rango
            </GradientButton>

            <CustomDatagrid
                rows={rows}
                columns={columns}
                pageSize={5}
                onRowDoubleClick={handleCellEdit}
            />

            <Dialog
                sx={{ '& .MuiDialog-paper': { minWidth: '50%', backgroundColor: colors.card[200] } }}
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>
                    <ShinyText text="Editar Rango de Impuesto Nacional" disabled={false} speed={3} fontSize="20px" />
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CustomTextField
                                title="Rango Mínimo"
                                name="min"
                                value={selectedRow?.min || ''}
                                onChange={(e) => setSelectedRow({ ...selectedRow, min: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                title="Rango Máximo"
                                name="max"
                                value={selectedRow?.max || ''}
                                onChange={(e) => setSelectedRow({ ...selectedRow, max: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                title="Valor USD"
                                name="importValue"
                                value={selectedRow?.importValue || ''}
                                onChange={(e) => setSelectedRow({ ...selectedRow, importValue: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <GradientButton
                        gradient={"red"}
                        onClick={handleDeleteRow}
                    >Eliminar</GradientButton>
                    <GradientButton
                        onClick={handleSaveRow}
                    >Guardar</GradientButton>
                    <GradientButton
                        gradient={"red"}
                        onClick={handleCloseDialog}
                    >Cancelar</GradientButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default NationalTaxesSettings;
