import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ results = [] }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Procesamiento de datos
  const salesData = results.map(item => parseFloat(item.qtySale) || 0);
  const costData = results.map(item => parseFloat(item.qtyproduct) || 0);
  const labels = results.map(item => item.date);

  const data = {
    labels,
    datasets: [
      {
        label: 'Valor de Venta',
        data: salesData,
        fill: false,
        backgroundColor: '#0064FB',
        borderColor: '#0064FB',
        tension: 0.4,
      },
      {
        label: 'Costo',
        data: costData,
        fill: false,
        backgroundColor: '#1ECDF8',
        borderColor: '#1ECDF8',
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: colors.primary[100],
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: false,
        text: 'Gráfica de Líneas - Valor de Venta vs Costo',
        color: colors.primary[100],
      },
    },
    scales: {
      x: {
        ticks: {
          color: colors.primary[100],
        },
        grid: {
          color: colors.primary[100],
        },
      },
      y: {
        ticks: {
          color: colors.primary[100],
        },
        grid: {
          display: false,
          color: colors.primary[500],
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default LineChart;