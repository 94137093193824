import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import ShinyText from '../../Global/ShinyText/ShinyText';
import GradientButton from '../../Global/GradientButton/GradientButton';

const Questions = ({ question, image, title, price, user, isCustomer }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box
            sx={{
                background: colors.primary[400],
                padding: { xs: "12px", md: "30px" },
                width: "100%",
                borderRadius: "15px",
                boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                color: colors.primary[100],
                marginBottom: "20px",
                marginLeft: { xs: "0px", md: "15px" },
                minheight: "250px"
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', mb: "10px" }}>
                    <ShinyText text={user} disabled={false} speed={3} fontSize="18px" />
                </Grid>

                <Grid item xs={12} sx={{ background: colors.primary[980], padding: "10px", borderRadius: '10px', marginLeft: "10px" }}>
                    <Typography variant="body1" sx={{ paddingLeft: '10px', fontFamily: 'Arial, sans-serif', marginBottom: "5px", color: colors.primary[100] }}>{question} </Typography>
                </Grid>

                <Grid item xs={12} lg={8} sx={{ display: "flex", flexDirection: "row" }}>
                    <Box sx={{ display: "flex", width: '150px', height: '80px', objectFit: 'contain', borderRadius: '20px', bgcolor: "white", padding: "5px" }}>
                        <img src={image} alt={title} style={{ width: '100%', height: 'auto', objectFit: 'contain', borderRadius: '20px' }} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "left", marginLeft: "20px" }}>
                        <Typography
                            variant="h5"
                            sx={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitLineClamp: 2,
                                fontWeight: 600
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography variant="h5">$ {price}</Typography>
                    </Box>
                </Grid>

                {isCustomer ? (
                    <>
                        <Grid item xs={12} lg={4} sx={{ display: 'flex', flexDirection: { xs: "column", lg: "row" }, justifyContent: 'center', alignItems: 'center' }}>
                            <GradientButton>
                                Ir Al Chat
                            </GradientButton>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} lg={4} sx={{ display: 'flex', flexDirection: { xs: "column", lg: "row" }, justifyContent: 'center', alignItems: 'center' }}>
                            <GradientButton>
                                Responder
                            </GradientButton>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default Questions;
