import React, { useState } from 'react';
import { Box, Grid, Typography, MenuItem, Select, InputLabel, FormControl, useTheme } from '@mui/material';
import { mockCategories } from '../../../mockdata/Mockdata';
import ShinyText from '../../Global/ShinyText/ShinyText';
import { tokens } from '../../../theme';
import GradientButton from '../../Global/GradientButton/GradientButton';
import CustomTextField from '../../Global/CustomTextField/CustomTextField';

const SearchPublisher = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [category, setCategory] = useState('');
    const [qty, setQty] = useState(500);
    const [subCategory, setSubCategory] = useState('');
    const [extraCategory, setExtraCategory] = useState('');
    const [productCount, setProductCount] = useState(0);
    const categories = mockCategories;

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
        setSubCategory('');
        setExtraCategory('');
        setProductCount(0);
    };

    const handleSubCategoryChange = (event) => {
        setSubCategory(event.target.value);
        setExtraCategory('');
        setProductCount(0);
    };

    const handleExtraCategoryChange = (event) => {
        setExtraCategory(event.target.value);
        setProductCount(0);
    };

    const handleSearch = () => {
        const selectedCategory = categories.find((cat) => cat.id === category);
        const selectedSubCategory = selectedCategory?.subcategories.find((sub) => sub.id === subCategory);
        const selectedSubSubCategory = selectedSubCategory?.subcategories.find((subSub) => subSub.name === extraCategory);
        setProductCount(selectedSubSubCategory ? selectedSubSubCategory.productCount : 0);
    };

    const handleQtyChange = (event) => {
        const newQty = parseInt(event.target.value, 10);
        if (newQty <= 500) {
            setQty(newQty);
        }
    };

    const selectedCategory = categories.find((cat) => cat.id === category);
    const subcategories = selectedCategory ? selectedCategory.subcategories : [];
    const selectedSubCategory = subcategories.find((sub) => sub.id === subCategory);
    const subSubcategories = selectedSubCategory ? selectedSubCategory.subcategories : [];

    return (
        <Box
            sx={{
                background: colors.primary[400],
                padding: { xs: '12px', md: '30px' },
                width: '100%',
                borderRadius: '15px',
                boxShadow: 'rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                color: colors.primary[100],
                marginBottom: '5px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Box sx={{ textAlign: "center" }}>
                <ShinyText text="Publica Tus Productos Por Categorias" disabled={false} speed={3} fontSize="25px" />
            </Box>
            <Typography
                variant="h5"
                sx={{ my: "15px", textAlign: "center", color: colors.primary[100] }}
            >
                ¡Recuerda que el límite por trabajo es de 500 productos diarios!
            </Typography>
            <Box sx={{ padding: { xs: "10px", md: "20px" }, width: "95%", borderRadius: "20px" }}>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="category-select-label">Categoría</InputLabel>
                            <Select
                                labelId="category-select-label"
                                id="category-select"
                                value={category}
                                label="Categoría"
                                onChange={handleCategoryChange}
                                sx={{ width: '100%', display: "flex", flexDirection: "row", backgroundColor: colors.primary[980] }}
                                size="medium"
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: colors.primary[980],
                                        },
                                    },
                                }}
                            >
                                {categories.map((cat) => (
                                    <MenuItem key={cat.id} value={cat.id}>
                                        {cat.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth disabled={!category}>
                            <InputLabel id="subcategory-select-label">Subcategoría</InputLabel>
                            <Select
                                labelId="subcategory-select-label"
                                id="subcategory-select"
                                value={subCategory}
                                label="Subcategoría"
                                onChange={handleSubCategoryChange}
                                sx={{ width: '100%', display: "flex", flexDirection: "row", backgroundColor: colors.primary[980] }}
                                size="medium"
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: colors.primary[980],
                                        },
                                    },
                                }}
                            >
                                {subcategories.map((subCat) => (
                                    <MenuItem key={subCat.id} value={subCat.id}>
                                        {subCat.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth disabled={!subCategory}>
                            <InputLabel id="subsubcategory-select-label">Sub-subcategoría</InputLabel>
                            <Select
                                labelId="subsubcategory-select-label"
                                id="subsubcategory-select"
                                value={extraCategory}
                                label="Sub-subcategoría"
                                onChange={handleExtraCategoryChange}
                                sx={{ width: '100%', display: "flex", flexDirection: "row", backgroundColor: colors.primary[980] }}
                                size="medium"
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: colors.primary[980],
                                        },
                                    },
                                }}
                            >
                                {subSubcategories.map((subSubCat) => (
                                    <MenuItem key={subSubCat.id} value={subSubCat.name}>
                                        {subSubCat.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "center", marginTop: "20px" }}>
                        <GradientButton
                            onClick={handleSearch}
                            disabled={!extraCategory}>
                            Buscar Productos
                        </GradientButton>
                    </Grid>


                    <Grid item xs={12} >
                        <Box sx={{ textAlign: "center", bgcolor: colors.card[200], mt: "10px", padding: "15px", borderRadius: "15px" }}>
                            <ShinyText text={"Productos en esta categoría: " + productCount} disabled={false} speed={3} fontSize="20px" />
                            <Box sx={{ mt: "20px" }}>
                                <CustomTextField
                                    title={"Cantidad de Productos a Publicar"}
                                    value={qty}
                                    onChange={handleQtyChange}
                                />
                            </Box>
                            <Typography
                                variant="h5"
                                sx={{ my: "15px", textAlign: "center", color: colors.primary[100] }}
                            >
                                Se realizará la publicación de los 500 primeros productos encontrados en dicha categoría
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", gap: 2, mt: "15px", mb: "30px" }}>
                                <GradientButton
                                    onClick={handleSearch}
                                    disabled={!extraCategory}
                                    gradient={"red"}>
                                    Reiniciar Filtro
                                </GradientButton>
                                <GradientButton
                                    onClick={handleSearch}
                                    disabled={!extraCategory}>
                                    Iniciar Publicación
                                </GradientButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
};

export default SearchPublisher;
