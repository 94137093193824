import { Box, Drawer, IconButton, List, ListItem, ListItemText, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PublicIcon from '@mui/icons-material/Public';
import { useNavigate } from "react-router-dom";
import TotalSales from "../../components/Global/TotalSales/TotalSales";
import { useSelector } from "react-redux";
import TotalProducts from "../../components/Global/TotalProducts/TotalProducts";
import { useAuth0 } from "@auth0/auth0-react";


const Topbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const mode = theme.palette.mode
  const filteredUsers = {};
  const colorMode = useContext(ColorModeContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const user = useSelector((state) => state.User);
  const { logout } = useAuth0();


  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setShowDropdown(true);
  };

  const handlelogOutClick = () => {
    logout()
  };

  const handleOutsideClick = (event) => {
    if (
      event.target.closest(".search-results") === null &&
      event.target !== document.querySelector(".search-results")
    ) {
      setShowDropdown(false);
    }
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: { xs: 2, md: 3 }, width: "100%", alignSelf: "center" }}>
      <Box sx={{ display: { xs: "flex", md: "none" }, justifyContent: "center", alignItems: "center", mr: "5px" }}>
        <IconButton onClick={() => setDrawerOpen(true)}>
          <MenuOutlinedIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box width="100%">
          <Box
            display="flex"
            backgroundColor={colors.primary[500]}
            borderRadius="10px"
            sx={{ width: { xs: "100%", md: "300px" }, boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", }}

          >
            <InputBase
              sx={{
                ml: 2, flex: 1, color: "white",
                "& ::placeholder": {
                  opacity: 0.9,
                },
              }}
              placeholder="Buscar"
              value={searchQuery}
              onChange={handleSearchInputChange}
              onClick={() => setShowDropdown(true)}
            />
            <IconButton type="button" sx={{ p: 2, color: "white" }}>
              <SearchIcon />
            </IconButton>
          </Box>

          {/* DROPDOWN */}
          {showDropdown && searchQuery && filteredUsers.length > 0 && (
            <Box
              className="search-results"
              backgroundColor={colors.primary[900]}
              position="absolute"
              mt={1}
              p={1}
              borderRadius="3px"
              boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
              zIndex="9999"
              onClick={handleOutsideClick}
              sx={{ width: { xs: "91%", md: "25%" }, top: { xs: "70px", md: "50px" } }}
            >
              {filteredUsers.slice(0, 5).map((user) => (
                <div
                  key={user.id}
                  style={{
                    padding: "2px",
                    cursor: "pointer",
                    backgroundColor: "transparent",
                    borderRadius: "3px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "grey";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "transparent";
                  }}
                  onClick={() => {
                    setSearchQuery("");
                    setShowDropdown(false);
                  }}
                >
                  <p style={{ marginLeft: "5px" }}>
                    {user.name} {user.lastname} ({user.company} {user.nit})
                  </p>
                </div>
              ))}
            </Box>
          )}

          {/* NO MATCHES */}
          {showDropdown && searchQuery && filteredUsers.length === 0 && (
            <Box
              className="search-results"
              backgroundColor={colors.primary[900]}
              position="absolute"
              mt={1}
              p={1}
              borderRadius="3px"
              boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
              zIndex="9999"
              onClick={handleOutsideClick}
              sx={{ width: { xs: "91%", md: "25%" }, top: { xs: "70px", md: "50px" } }}
            >
              <div style={{ padding: "2px" }}>
                <p style={{ marginLeft: "5px" }}>No Matches</p>
              </div>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
          <TotalProducts total={user?.total ? user.total : "7K"} percent={"90"} />
          <TotalSales total={user?.total ? user.total : "$103M"} percent={"40"} />
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <IconButton onClick={colorMode.toggleColorMode} sx={{ width: "35px", height: "35px" }}>
            {theme.palette.mode === "dark" ? (
              <LightModeOutlinedIcon />
            ) : (
              <DarkModeOutlinedIcon />
            )}
          </IconButton>
          <IconButton onClick={handlelogOutClick} sx={{ display: { xs: "none", md: "flex" }, width: "35px", height: "35px" }}>
            <ExitToAppIcon />
          </IconButton>
          <Box sx={{ justifyContent: "center", alignItems: "center", display: { xs: "none", md: "flex" } }}>
            <img src="/profile.jpg" alt="profile" style={{ width: "50px", cursor: "pointer", borderRadius: "50%", boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }} onClick={() => { navigate("/settings/profile") }} />
          </Box>
        </Box>
      </Box>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: colors.primary[980]
          }
        }}
      >
        <Box
          style={{
            margin: "10px",
            color: colors.primary[500],
            display: "flex",
          }}
        >
          <Box display="flex"
            justifyContent="center"
            alignItems="center"
            margin="5px"
          >
            {mode === "dark" ? (
              <img src="/logo.png" alt="logo" style={{ maxWidth: "100%", height: "auto" }} />) : (
              <img src="/logo.png" alt="logo" style={{ maxWidth: "100%", height: "auto" }} />)}
          </Box>
          <IconButton onClick={() => setDrawerOpen(false)} style={{ margin: "15px" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box paddingLeft={"5%"} sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
          <List>
            <ListItem button onClick={() => setDrawerOpen(false)}>
              <SpaceDashboardIcon sx={{ marginRight: "10px" }} />
              <ListItemText onClick={() => navigate("/home")}>Dashboard</ListItemText>
            </ListItem>
            <ListItem button onClick={() => setDrawerOpen(false)}>
              <SettingsIcon sx={{ marginRight: "10px" }} />
              <ListItemText onClick={() => navigate("/settings/profile")}>Configuración</ListItemText>
            </ListItem>
            <ListItem button onClick={() => setDrawerOpen(false)}>
              <ShoppingBagIcon sx={{ marginRight: "10px" }} />
              <ListItemText onClick={() => navigate("/shop/talk")}>Tienda</ListItemText>
            </ListItem>
            <ListItem button onClick={() => setDrawerOpen(false)}>
              <AnalyticsIcon sx={{ marginRight: "10px" }} />
              <ListItemText onClick={() => navigate("/results")}>Resultados</ListItemText>
            </ListItem>
            <ListItem button onClick={() => setDrawerOpen(false)}>
              <LoyaltyIcon sx={{ marginRight: "10px" }} />
              <ListItemText onClick={() => navigate("/shipping")}>Entregas</ListItemText>
            </ListItem>
            <ListItem button onClick={() => setDrawerOpen(false)}>
              <PublicIcon sx={{ marginRight: "10px" }} />
              <ListItemText onClick={() => navigate("/publisher/shop")}>Publicador</ListItemText>
            </ListItem>
            <ListItem button onClick={() => setDrawerOpen(false)}>
              <ExitToAppIcon sx={{ marginRight: "10px" }} />
              <ListItemText onClick={handlelogOutClick}>Cerrar Sesión</ListItemText>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box >
  );
};

export default Topbar;
