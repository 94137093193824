import { useTheme } from '@mui/material';
import './ShinyText.css';
import { tokens } from '../../../theme';

const ShinyText = ({ text, disabled = false, speed = 5, className = '', fontSize = "20px", margin="0px"}) => {
    const animationDuration = `${speed}s`;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <div
            className={`shiny-text ${disabled ? 'disabled' : ''} ${className}`}
            style={{
                animationDuration, color: colors.grey[980], fontSize: fontSize, display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                margin: margin
            }}
        >
            {text}
        </div>
    );
};

export default ShinyText;