import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import GradientButton from '../GradientButton/GradientButton';
import "./BoxButton.css";

const BoxButton = ({ active, icon, title, buttonText, onClick, status }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(onClick);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 2,
        borderRadius: "15px",
        background: colors.primary[980],
        width: "100%",
        position: 'relative',
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"
      }}
      className={`box-button ${active ? 'box-button-active' : ''}`}
    >
      {status === 'develop' && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#FF9800',
            color: '#fff',
            padding: '2px 8px',
            borderRadius: '15px 1px 5px 1px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          En desarrollo
        </Box>
      )}
      <Box sx={{ flex: 1 / 8 }}>
        <Box sx={{ padding: "10px", display: "flex", justifyContent: "center", bgcolor: colors.primary[980], borderRadius: "50%", width: "40px", height: "40px" }}>
          {icon}
        </Box>
      </Box>
      <Box sx={{ flex: 4 / 8 }}>
        <Typography variant="h5" sx={{ fontWeight: "600" }}>{title}</Typography>
      </Box>
      <Box sx={{ flex: 3 / 8 }}>
        <GradientButton onClick={handleClick}>
          {active ? "Seleccionado" : buttonText}
        </GradientButton>
      </Box>
    </Box>
  );
};

export default BoxButton;
