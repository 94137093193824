import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import TimeSettings from './TimeSettings';
import WarrantySettings from './WarrantySettings';
import DescriptionSettings from './DescriptionSettings';

const ShippingSettings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: "center",
        borderRadius: "15px",

      }}
    >

      <Grid container spacing={3}>

        <Grid item xs={12}>
          <TimeSettings time={10} />
        </Grid>

        <Grid item xs={12}>
          <WarrantySettings Warranty={30} />
        </Grid>

        <Grid item xs={12}>
          <DescriptionSettings description={'Bienvenido, gracias por la confianza en nuestro servicio, manejamos un tiempo de entrega de 15 a 30 días hábiles sin contar fines de semana ni días festivo, los precios de los productos no incluyen el 19% del iva, si desea factura debes pagarlo,  cualquier duda o inquietud que tengas con tu pedido comunícate con nosotros.  Buen día.'} />
        </Grid>

      </Grid>
    </Box>

  );
};

export default ShippingSettings;
