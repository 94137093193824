import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import ShinyText from '../../Global/ShinyText/ShinyText';
import CustomTextField from '../../Global/CustomTextField/CustomTextField';
import GradientButton from '../../Global/GradientButton/GradientButton';

const DescriptionSettings = ({ description }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [localDescription, setLocalDescription] = useState(description);

    const handleDescriptionChange = (event) => {
        setLocalDescription(event.target.value);
    };

    return (
        <Box
            sx={{
                background: colors.primary[400],
                padding: "30px",
                borderRadius: "15px",
                boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: "center" }}>
                    <ShinyText text="Descripción de la Tienda" disabled={false} speed={3} fontSize="25px" />
                </Grid>

                <Grid item xs={12}>
                    <CustomTextField
                        value={localDescription}
                        onChange={handleDescriptionChange}
                        placeholder="Modificar descripción de la tienda"
                        title={"Modificar descripción"}
                    />
                </Grid>

                <Grid item xs={12} sx={{ alignItems: "center" }}>
                    <GradientButton>
                        Guardar
                    </GradientButton>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DescriptionSettings;
