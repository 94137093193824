import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React from "react";
import { tokens } from "../../../theme";

const CustomDatagrid = ({ rows, columns, pageSize, onRowDoubleClick, ...props }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 8px 0px",
        borderRadius: "10px",
        "& .MuiDataGrid-root": {
          border: "none",
          borderRadius: "10px",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[800],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.primary[500],
        },
        "& .MuiTablePagination-root": {
          color: "#ffffff", // Cambia el color del texto en el footer
        },
        "& .MuiDataGrid-toolbarContainer": {
          bgcolor: `${colors.primary[500]}`,
          mb: "3px"
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `#ffffff !important`,
        },
        height: "500px",
        width: "100%",
        my: "10px"
      }}

    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        slots={{ toolbar: GridToolbar }}
        onRowDoubleClick={onRowDoubleClick}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default CustomDatagrid;
