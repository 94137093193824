import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import EditPost from '../EditPost/EditPost';
import ProductBlocks from '../ProductBlocks/ProductBlocks';
import TalkClients from '../TalkClients/TalkClients';
import UpdateProducts from '../UpdateProducts/UpdateProducts';

const ContentComponent = ({ activepage }) => {

  return (
    <Box>
      <Grid container spacing={2} sx={{ padding: { xs: "10px", md: "20px" } }} justifyContent={"space-around"}>
        <Grid item xs={12}>
          {activepage === 'edit' && <EditPost renderCard={3} />}
          {activepage === 'blocks' && <ProductBlocks />}
          {activepage === 'talk' && <TalkClients />}
          {activepage === 'support' && <UpdateProducts />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentComponent;
