import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import TaxesSettings from './TaxesSettings';
import CostSettings from './CostSettings';
import TypeSettings from './typeSettings';
import UtilitySettings from './utilitySettings';
import ImportSettings from './importSettings';
import NationalTaxesSettings from './nationalTaxesSettings';

const PriceSettings = () => {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', borderRadius: '15px', padding: '5px' }}>
      <Box sx={{ padding: { xs: '0px', md: '20px' }, width: '100%', borderRadius: '20px' }}>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <UtilitySettings minutility={0} maxutility={5} utility={2} />
          </Grid>

          <Grid item xs={12}>
            <ImportSettings minImport={1} maxImport={2} Import={4.78} />
          </Grid>

          <Grid item xs={12}>
            <NationalTaxesSettings minTaxes={1} maxTaxes={1} Taxes={2.43} />
          </Grid>

          <Grid item xs={12} >
            <TaxesSettings taxes={7} />
          </Grid>

          <Grid item xs={12} >
            <CostSettings cost={10500} userCost={30000} />
          </Grid>

          <Grid item xs={12} >
            <TypeSettings />
          </Grid>

        </Grid>
      </Box>
    </Box>
  );
};

export default PriceSettings;
