import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid, useTheme, Modal, TextField } from '@mui/material';
import { tokens } from '../../../theme';
import GradientButton from '../GradientButton/GradientButton';
import ShinyText from '../ShinyText/ShinyText';
import { useNavigate } from 'react-router-dom';

const formatPrice = (price) => {
  return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(price);
};

const Card = ({ images, title, amazonPrice, rating, link, onUpdate, actionType, buttonText }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const imagenProduct = images || "https://ventaequipos.com/shop/assets/default_image.png";
  const defaultImage = "https://ventaequipos.com/shop/assets/default_image.png";
  const [imageSrc, setImageSrc] = useState(imagenProduct);

  const maxRetries = 5;
  const handleImageError = (event) => {
    if (event.target.retryCount === undefined || event.target.retryCount === null) {
      event.target.retryCount = 1;
    } else if (event.target.retryCount < maxRetries) {
      event.target.retryCount++;
      setTimeout(() => {
        setImageSrc(event.target.src);
      }, 1000);
      return;
    }
    setImageSrc(defaultImage);
  };

  // Modal state
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    description: 'Este es un producto de alta calidad que te ayudará a mejorar tu experiencia diaria. Ideal para uso personal o profesional.',
    utility: 50000,
    price: amazonPrice || 150000,
    tax: 19000,
    shippingCost: 25000,
    generalDescription: 'Este **producto importado de alta calidad** es ideal para aquellos que buscan lo mejor en desempeño y durabilidad. Con una **garantía de 1 año** desde la fecha de compra, tendrás la seguridad de que estás invirtiendo en un artículo confiable y de excelente rendimiento. Gracias a su **innovador diseño**, se adapta perfectamente a cualquier ocasión, ya sea para uso personal o profesional. **Tiempo de entrega**: Debido a su origen internacional, el producto llegará en un plazo aproximado de **7 días hábiles**, asegurando que recibas tu compra en el menor tiempo posible, con un proceso de envío seguro y eficaz. Aprovecha nuestra **promoción especial** y **descuentos exclusivos** para compras anticipadas. ¡No dejes pasar la oportunidad de obtener este excelente producto a un precio inigualable!'
  });


  const navigate = useNavigate();

  // Handle modal open/close
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handlePublish = () => {
    // Aquí puedes hacer la lógica para publicar en MercadoLibre
    console.log('Publicando en MercadoLibre con los siguientes datos:', formData);
    handleCloseModal(); // Cierra el modal
  };

  const handleUpdate = () => {
    // Redirigir a MercadoLibre
    window.open("https://www.mercadolibre.com.co", "_blank");
  };

  return (
    <Box sx={{ background: colors.card[200], minHeight: { xs: "200px", md: "420px" }, borderRadius: "15px", boxShadow: "rgba(50, 50, 93, 0.2) 0px 6px 12px -2px, rgba(0, 0, 0, 0.2) 0px 3px 7px -3px", display: "flex", flexDirection: "column", justifyContent: "space-between", margin: {xs:"5px", md:"8px"} }}>
      <Box sx={{ bgcolor: colors.card[100], padding: { xs: "15px 20px 15px 20px", md: "25px" }, borderRadius: "15px", boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 8px" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "15px", marginTop: "10px", height: { xs: "80px", md: "150px" }, padding: "10px", bgcolor: "#ffffff" }}>
          <img
            src={imageSrc}
            onError={handleImageError}
            alt={title}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </Box>
        <Box sx={{ mt: "10px", mb: { xs: "45px", md: "30px" } }}>
          <ShinyText text={title} disabled={false} speed={3} fontSize='15px' />
        </Box>
      </Box>
      <Box sx={{ width: {xs:"90%", md:"80%"}, alignSelf: "center", bgcolor: colors.primary[400], padding: {xs:"10px", md:"15px"}, borderRadius: "15px", position: "relative", bottom: "48px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", flexDirection:{xs:"column", lg:"row"} }}>
            <Typography variant="h6" sx={{fontSize:{xs:"10px",md:"14px"}}}>{buttonText === "Modificar" ? "Precio ML" : "Precio Amazon"}</Typography>
            <Typography variant="h5" sx={{ color: "#0064FB", fontSize: {xs:"10px",md:"14px"} }}>{formatPrice(formData.price)}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", flexDirection:{xs:"column", lg:"row"} }}>
            <Typography variant="h6" sx={{fontSize:{xs:"10px",md:"14px"}}}>{buttonText === "Modificar" ? "Enlace ML" : "Enlace Amazon"}</Typography>
            <Typography variant="body2" sx={{ color: "#0064FB", fontSize: {xs:"10px",md:"14px"} }}>
              <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>Link</a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ height: "30px", width: "80%", justifySelf: "center", alignSelf: "center", position: "relative", bottom: "28px" }}>
        <GradientButton onClick={actionType === 'publish' ? handleOpenModal : actionType === 'update' ? handleUpdate : null}>
          {buttonText}
        </GradientButton>
      </Box>

      {/* Modal de publicación */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
          bgcolor: colors.card[200], boxShadow: 24, p: 4, width: { xs: '90%', md: "50%" }, borderRadius: '8px'
        }}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: "5px" }}>
            <ShinyText text="Publicar en MercadoLibre" disabled={false} speed={3} fontSize='18px' />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "15px", marginTop: "10px", height: { xs: "90px", md: "150px" }, padding: "10px", bgcolor: "#ffffff", width: "300px", alignSelf: "center", justifySelf: "center" }}>
            <img src={imageSrc} alt="Product" style={{ width: "100px", height: "100px", objectFit: "contain" }} />
          </Box>

          <TextField
            label="Descripción General"
            name="generalDescription"
            value={formData.generalDescription}
            onChange={handleFormChange}
            fullWidth
            multiline
            rows={7}
            sx={{ mt: '20px' }}
          />
          <TextField
            label="Descripción"
            name="description"
            value={formData.description}
            onChange={handleFormChange}
            fullWidth
            sx={{ my: '10px' }}
          />
          <TextField
            label="Utilidad"
            name="utility"
            type="number"
            value={formData.utility}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: '10px' }}
          />
          <TextField
            label="Precio"
            name="price"
            type="number"
            value={formData.price}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: '10px' }}
          />
          <TextField
            label="Impuesto"
            name="tax"
            type="number"
            value={formData.tax}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: '10px' }}
          />
          <TextField
            label="Costo de Envío"
            name="shippingCost"
            type="number"
            value={formData.shippingCost}
            onChange={handleFormChange}
            fullWidth
            sx={{ marginBottom: '20px' }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <GradientButton onClick={handleCloseModal} gradient={"red"}>Cancelar</GradientButton>
            <GradientButton onClick={handlePublish}>Publicar</GradientButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Card;
