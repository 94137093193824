import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import GradientButton from '../../Global/GradientButton/GradientButton';
import ShinyText from '../../Global/ShinyText/ShinyText';
import CustomTextField from '../../Global/CustomTextField/CustomTextField';

const ClientsSettings = ({ clients }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [localClients, setLocalClients] = useState(clients);

  const handleClientsChange = (event) => {
    setLocalClients(event.target.value);
  };

  return (
    <Box
      sx={{
        background: colors.primary[400],
        padding: '35px',
        borderRadius: '15px',
        boxShadow: 'rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <ShinyText text="Respuestas rápidas y mensajes de venta" disabled={false} speed={3} margin="0px 0px 20px 0px" fontSize="25px" />
      </Box>
      <CustomTextField
        title={"Mensaje automático de venta"}
        value={localClients}
        onChange={handleClientsChange}
        placeholder="Digita el mensaje"
        height="200px"
      />

      <GradientButton
        onClick={() => console.log(`Mensaje actualizado: ${localClients}`)}
      >Actualizar el Mensaje</GradientButton>
    </Box>
  );
};

export default ClientsSettings;