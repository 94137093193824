import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../../theme';
import GradientButton from '../GradientButton/GradientButton';

const CardTop = ({ name, total, image }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                bgcolor: colors.card[200],
                borderRadius: '10px',
                padding: '16px',
                marginBottom: '8px',
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
            }}
        >
            <Box sx={{ display: "flex", flex: 1 / 8 }}>
                <Avatar src={image}
                    alt="David"
                    sx={{ marginRight: '16px', width: "40px" }}>
                    P
                </Avatar>
            </Box>
            <Box sx={{ display: 'flex', flex: 6 / 8 }}>
                <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "flex-start" }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {name.split(" ")[0]} {name.split(" ")[1]?.charAt(0).toUpperCase()}.
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                        ${total}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: "flex", flex: 1 / 8 }}>
                <GradientButton
                    gradient="blue"
                    onClick={() => console.log('Like button clicked')}
                    styles={{ width: "40px", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}  // Tamaño y bordes del botón
                >
                    <FavoriteIcon sx={{ color: '#FFFFFF', width: "auto" }} />
                </GradientButton>
            </Box>
        </Box>
    );
};

export default CardTop;
