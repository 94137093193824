import { GET_USERS, GET_USER, GET_PRODUCTS } from "./actions";

const initialState = {
  TopUsers: [],
  User: { name: "Osneider", lastname: "Scarpetta" },
  ShopProducts: [],
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return { ...state, User: action.payload };
    case GET_PRODUCTS:
      return { ...state, ShopProducts: action.payload };
    case GET_USERS:
      return { ...state, TopUsers: action.payload };
    case GET_PRODUCTS:
      return { ...state, Products: action.payload };
    default:
      return state;
  }

};

export default rootReducer;
