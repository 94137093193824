import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Typography, Grid, useTheme, Snackbar, Alert } from '@mui/material';
import { tokens } from '../../theme';
import { useNavigate } from 'react-router-dom';
import { keyframes } from '@emotion/react';
import { useAuth0 } from '@auth0/auth0-react';
import GradientButton from '../../components/Global/GradientButton/GradientButton';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';

const Login = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isAnimating,] = useState(false);
    const [, setAccessToken] = useState(localStorage.getItem('accessToken'));
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const options = {
        particles: {
            number: {
                value: 150,
                density: {
                    enable: true,
                    area: 800
                }
            },
            color: {
                value: ["#ffffff", "#f7f7f7"]
            },
            shape: {
                type: "star"
            },
            opacity: {
                value: { min: 0.3, max: 1 },
                random: true
            },
            size: {
                value: { min: 1, max: 2 },
                random: true
            },
            links: {
                enable: false
            },
            move: {
                enable: true,
                speed: 0.3,
                direction: "none",
                random: true,
                straight: false,
                outModes: "out"
            }
        },
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: "grab"
                },
                onClick: {
                    enable: true,
                    mode: "push"
                }
            },
            modes: {
                repulse: {
                    distance: 100
                },
                push: {
                    quantity: 4
                }
            }
        }
    };


    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine);
    }, []);

    const {
        loginWithRedirect,
        isAuthenticated,
        // isLoading,
        // user,
        handleRedirectCallback,
        getAccessTokenSilently,
    } = useAuth0();

    useEffect(() => {
        const handleRedirect = async () => {
            try {
                if (window.location.search.includes('code=')) {
                    console.log('Manejando redireccionamiento...');
                    await handleRedirectCallback();
                    const token = await getAccessTokenSilently();
                    setAccessToken(token);
                }
            } catch (error) {
                console.error('Error manejando el redireccionamiento:', error);
            }
        };
        handleRedirect();
    }, [handleRedirectCallback, getAccessTokenSilently]);

    const handleAuth0Login = async () => {
        if (!isAuthenticated) {
            console.log('Redirigiendo para iniciar sesión...');
            await loginWithRedirect({
                authorizationParams: {
                    prompt: "login",
                }
            });
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const errorMessage = params.get("error_description");
        if (errorMessage) {
            setSnackbarMessage(errorMessage);
            setSnackbarOpen(true);
        }
    }, []);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const loginAnimation = keyframes`
        0% { transform: scale(1); opacity: 1; }
        100% { transform: scale(0.9); opacity: 0; }
    `;

    return (
        <Box sx={{
            height: '100vh', display: 'flex',
            position: "relative",
            flex: 1,
            backgroundImage: { xs: `url('planet.jpg')`, md: `url('fondo.png')` },
            backgroundSize: 'cover',
            backgroundPosition: "center",
            overflow: "hidden",
            zIndex: 1
        }}>
            <Particles options={options} init={particlesInit} />
            <Box sx={{
                width: "100%",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px',
            }}>
                <Box
                    className="login-box"
                    sx={{
                        width: { xs: '90%', md: '40%', lg: '25%' },
                        padding: '40px',
                        borderRadius: '15px',
                        backgroundColor: 'rgba(19,19,22,0.90)',
                        boxShadow: 'rgba(91, 223, 237, 0.3) 0px 54px 55px, rgba(91, 223, 237, 0.3) 0px -12px 30px',
                        animation: isAnimating ? `${loginAnimation} 0.5s ease forwards` : 'none',
                        zIndex: 1000
                    }}
                >
                    {/* Logo */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                        <img src="/logo.png" alt="Logo" style={{ height: 'auto', width: "70%" }} />
                    </Box>
                    <Typography variant="h4" sx={{ color: 'white', marginBottom: '20px', textAlign: 'center' }}>
                        Bienvenido
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <GradientButton onClick={handleAuth0Login}>
                                Iniciar Sesión
                            </GradientButton>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ color: 'white', textAlign: 'center', margin: '10px 0' }}>
                                o continúa con
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => loginWithRedirect({ authorizationParams: { connection: 'google-oauth2', prompt: "login" } })}
                                    sx={{
                                        marginRight: '10px',
                                        borderColor: 'gray',
                                        color: 'white',
                                        borderRadius: '10px',
                                        textTransform: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        '&:hover': {
                                            borderColor: '#1ECDF8',
                                            background: colors.primary[500]
                                        },
                                    }}
                                >
                                    <img src="google.png" alt="Google" style={{ width: '20px', marginRight: '10px', borderRadius: "50%" }} />
                                    Google
                                </Button>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => loginWithRedirect({ authorizationParams: { connection: 'facebook', prompt: "login" } })}
                                    sx={{
                                        borderColor: 'gray',
                                        color: 'white',
                                        borderRadius: '10px',
                                        textTransform: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        '&:hover': {
                                            borderColor: '#1ECDF8',
                                            background: colors.primary[500]
                                        },
                                    }}
                                >
                                    <img src="Facebook.png" alt="Facebook" style={{ width: '20px', marginRight: '10px', borderRadius: "50%" }} />
                                    Facebook
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box >
    );
};

export default Login;
