import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import ContentComponent from '../../components/Shop/ContentComponent/ContentComponent';
import { useParams } from 'react-router-dom';
import BarComponent from '../../components/Shop/BarComponent/BarComponent';
import ShinyText from '../../components/Global/ShinyText/ShinyText';

const Shop = () => {
    const { activepage } = useParams();
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "95%", borderRadius: "20px" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <ShinyText text="Mi Tienda" disabled={false} speed={3} />
                                <Typography variant="h5" sx={{ marginTop: "5px", marginBottom: "20px" }}>Bienvenido a la Página Principal de Tu Tienda</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <BarComponent activepage={activepage} />
                            </Grid>
                            <Grid item xs={12}>
                                <ContentComponent activepage={activepage} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Shop;