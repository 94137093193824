import Box from '@mui/material/Box';
import { Grid, useTheme } from '@mui/material';
import ClientsSettings from './ClientsSettings';
import AnswerFastSettings from './AnswerFastSettings';
import { useState } from 'react';
import { tokens } from '../../../theme';

const AppSettings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Box sx={{ width: "100%", borderRadius: "20px" }}>
                  <ClientsSettings clients={'Hola'} />
                <Box sx={{
                  bgcolor: colors.primary[400],
                  borderRadius: "15px",
                  padding: "15px",
                  my:"15px",
                  boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                }}>
                  <AnswerFastSettings />
                </Box>
            </Box>
          </Box>
    </Box>
  );
};

export default AppSettings;
