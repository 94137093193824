import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../../theme';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import GroupIcon from '@mui/icons-material/Group';
import CardTop from './CardTop';
import ShinyText from '../ShinyText/ShinyText';

const TopSellers = ({ sellers }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '20px',
        bgcolor: colors.primary[400],
        textAlign: 'center',
        padding: '16px',
        overflow: 'hidden',
        height: "100%",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '16px', gap: 2 }}>
        <GroupIcon fontSize="medium" />
        <ShinyText text="Top Vendedores" disabled={false} speed={3} />
      </Box>
      <Divider sx={{ bgcolor: 'grey.500', marginBottom: '16px' }} />
      {sellers.length > 0 ? (
        <Box>
          {sellers.map((seller, index) => (
            <CardTop key={index} name={seller.name} image={seller.image} total={seller.total ? seller.total : "0"} />
          ))}
        </Box>
      ) : (
        <Typography variant="h6">No hay vendedores disponibles</Typography>
      )}
    </Box>
  );
};

export default TopSellers;
