import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Divider, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import LockIcon from '@mui/icons-material/Lock';
import GppGoodIcon from '@mui/icons-material/GppGood';

const ProfileComponent = ({ image, name }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ background: colors.primary[400], borderRadius: "15px", height: "auto", padding: "20px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}>
      <Avatar
        src={image}
        alt="David"
        sx={{ width: 40, height: 40 }}
      />
      <Typography variant="h4">
        Bienvenido, {name}
      </Typography>
      <Typography variant="subtitle1" component="h3">
        En esta sección podrás configurar todo lo respectivo a la aplicación y a tu tienda.
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", mt: "25px", mb: "15px" }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px", borderRadius: "50%", backgroundColor: "#2ECC71", width: "40px", height: "40px" }}>
          <GppGoodIcon />
        </Box>
        <Typography variant="h5" sx={{ marginLeft: "10px", color: colors.grey[50] }}>Cuenta Verificada</Typography>
      </Box>
      <Divider sx={{ bgcolor: 'grey.500', marginBottom: '15px' }} />
      <Box sx={{ display: "flex", alignItems: "center", mt: "10px" }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px", borderRadius: "50%", backgroundColor: "#0064FB", width: "40px", height: "40px" }}>
          <LockIcon />
        </Box>
        <Typography variant="h5" sx={{ marginLeft: "10px", color: colors.grey[50] }}>Cuenta Segura</Typography>
      </Box>
    </Box>

  )
};

export default ProfileComponent; 
