import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Grid, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import { useNavigate } from 'react-router-dom';
import GradientButton from '../GradientButton/GradientButton';
import ShinyText from '../ShinyText/ShinyText';

const UserBanner = ({ image, name, city = "Bogotá D.C", time, total }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  return (
    <Box sx={{ background: colors.primary[400], borderRadius: "15px", height: "250px", padding: "10px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px", display: "flex", justifyContent: "space-between" }}>
      <Box
        sx={{
          flex: 2 / 5,
          height: "100%",
          borderRadius: "15px",
          overflow: "hidden",
          padding: "5px"
        }}
      >
        <img
          src="/discover.jpg"
          alt='portada'
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "15px",
          }}
        />
      </Box>
      <Box
        sx={{
          flex: 3 / 5,
          height: "100%",
          borderRadius: "15px",
          overflow: "hidden",
          padding: "5px"
        }}
      >
        <Grid container sx={{ gap: 3, padding: "15px" }}>
          <Grid item xs={12} sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Avatar
              src={image}
              alt="David"
              sx={{ width: { xs: 30, md: 40 }, height: { xs: 30, md: 40 } }}
            />
            <Box>
              <ShinyText text={name} disabled={false} speed={3} fontSize='16px' />
              <Typography variant="body1">
                {city}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="subtitle1" component="h3">
                Tiempo
              </Typography>
              <Typography variant="h6" sx={{ color: "#0064FB" }}>
                {time}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="subtitle1" component="h3">
                Total vendido
              </Typography>
              <Typography variant="h6" sx={{ color: "#0064FB" }}>
                {total}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, marginTop: "0px" }}>
              <GradientButton onClick={() => { navigate("/settings/profile") }}>
                Perfil
              </GradientButton>
              <GradientButton onClick={() => { navigate("/results") }} gradient="red">
                Tienda
              </GradientButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>

  )
};

export default UserBanner;
