import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import ShinyText from '../../Global/ShinyText/ShinyText';
import CustomTextField from '../../Global/CustomTextField/CustomTextField';
import GradientButton from '../../Global/GradientButton/GradientButton';

const Cards = ({ order, image, title, price, tracking, status, track, download }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [pedido, setPedido] = useState("");
  const [amazonTracking, setAmazonTracking] = useState("");
  const [infoAdicional, setInfoAdicional] = useState("");

  const handlePedidoChange = (event) => {
    setPedido(event.target.value);
  };

  const handleAmazonTrackingChange = (event) => {
    setAmazonTracking(event.target.value);
  };

  const handleInfoAdicionalChange = (event) => {
    setInfoAdicional(event.target.value);
  };

  return (
    <Box
      sx={{
        background: colors.primary[400],
        padding: { xs: "12px", md: "30px" },
        width: "100%",
        borderRadius: "15px",
        boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        color: colors.primary[100],
        margin: "5px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: "15px" }}>
        <ShinyText text={"Orden Número: " + order} disabled={false} speed={3} fontSize='19px' />
      </Box>
      <Box sx={{ bgcolor: colors.card[100], display: "flex", padding: "10px", borderRadius: "15px", justifyContent: "space-around" }}>
        <Box sx={{ display: "flex", width: { xs: '150px', md: '190px' }, height: { xs: '110px', md: '130px' }, objectFit: 'contain', borderRadius: '20px', bgcolor: "white", padding: "8px", flex: 1/3 }}>
          <img src={image} alt={title} style={{ width: '100%', height: 'auto', objectFit: 'contain', borderRadius: '20px' }} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", flex: 2/3, marginLeft:"10px"}}>
          <ShinyText text={title} disabled={false} speed={3} fontSize='16px' />
          <Typography variant="h5" sx={{ color: colors.primary[100], fontWeight: 700, mb: "10px" }}>{price}</Typography>
          <GradientButton gradient={download === true ? "red" : "blue"}>
            {download === true ? "Descargar Etiqueta" : "Volver a Descargar Etiqueta"}
          </GradientButton>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", my: "10px" }}>
        <Typography variant="h5" sx={{ color: colors.primary[100] }}>Número de Guía: {track}</Typography>
        <Typography variant="h5" sx={{ color: colors.primary[100] }}>Estado: {status}</Typography>
        <Typography variant="h5" sx={{ color: colors.primary[100] }}>Guía USA: {tracking}</Typography>
      </Box>
      <CustomTextField
        value={pedido}
        onChange={handlePedidoChange}
        placeholder="Número de Pedido"
        title="Número de Pedido"
      />
      <CustomTextField
        value={amazonTracking}
        onChange={handleAmazonTrackingChange}
        placeholder="Tracking de Amazon"
        title="Tracking de Amazon"
      />
      <CustomTextField
        value={infoAdicional}
        onChange={handleInfoAdicionalChange}
        placeholder="Información Adicional"
        title="Información Adicional"
      />
      <GradientButton>Guardar</GradientButton>

    </Box>
  );
};

export default Cards;
