import Box from '@mui/material/Box';
import { Grid, Typography, useTheme, Modal, Button } from '@mui/material';
import LineChart from '../../components/Charts/LineChart';
import { tokens } from '../../theme';
import DonutChart from '../../components/Charts/DonutChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EditIcon from '@mui/icons-material/Edit';
import { sampleRows } from '../../mockdata/Mockdata';
import { useState } from 'react';
import ShinyText from '../../components/Global/ShinyText/ShinyText';
import CustomDatagrid from '../../components/Global/CustomDatagrid/CustomDatagrid';
import GradientButton from '../../components/Global/GradientButton/GradientButton';

const Results = () => {
    const [year, setYear] = useState(2025);
    const [month, setMonth] = useState("Enero");
    const [filteredRows, setFilteredRows] = useState(sampleRows);

    const [isMonthModalOpen, setMonthModalOpen] = useState(false);
    const [isYearModalOpen, setYearModalOpen] = useState(false);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const monthMapping = {
        Enero: 0, Febrero: 1, Marzo: 2, Abril: 3, Mayo: 4, Junio: 5,
        Julio: 6, Agosto: 7, Septiembre: 8, Octubre: 9, Noviembre: 10, Diciembre: 11
    };

    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    const getAvailableYears = () => {
        const years = new Set();
        sampleRows.forEach((row) => {
            const rowDate = new Date(row.date);
            years.add(rowDate.getFullYear());
        });
        return Array.from(years);
    };

    const getAvailableMonthsForYear = (selectedYear) => {
        const monthsInYear = new Set();
        sampleRows.forEach((row) => {
            const rowDate = new Date(row.date);
            if (rowDate.getFullYear() === selectedYear) {
                monthsInYear.add(monthNames[rowDate.getMonth()]);
            }
        });
        return Array.from(monthsInYear);
    };

    const filterData = (selectedMonth, selectedYear) => {
        const filtered = sampleRows.filter(row => {
            const rowDate = new Date(row.date);
            return rowDate.getMonth() === monthMapping[selectedMonth] && rowDate.getFullYear() === selectedYear;
        });
        setFilteredRows(filtered);
    };

    const filterByYear = (selectedYear) => {
        setYear(selectedYear);
        const availableMonths = getAvailableMonthsForYear(selectedYear);
        if (!availableMonths.includes(month)) {
            setMonth(availableMonths[0]);
        }
        filterData(month, selectedYear);
        setYearModalOpen(false);
    };

    const filterByMonth = (selectedMonth) => {
        setMonth(selectedMonth);
        filterData(selectedMonth, year);
        setMonthModalOpen(false);
    };

    const calculateTotalSales = () => {
        const total = filteredRows.reduce((total, row) => {
            const qtySale = parseFloat(row.utility) || 0;
            return total + qtySale;
        }, 0);

        return formatNumber(total);
    };

    const formatNumber = (num) => {
        if (num >= 1e9) {
            return `${(num / 1e9).toFixed(1)}B`;
        } else if (num >= 1e6) {
            return `${(num / 1e6).toFixed(1)}M`;
        } else if (num >= 1e3) {
            return `${(num / 1e3).toFixed(1)}k`;
        }
        return num.toFixed(2);
    };

    const userColumns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'date', headerName: 'Fecha', flex: 1 },
        { field: 'title', headerName: 'Título', flex: 1 },
        { field: 'id_venta', headerName: 'ID Venta', flex: 1 },
        { field: 'id_meli', headerName: 'ID Meli', flex: 1 },
        { field: 'sku', headerName: 'SKU', flex: 1 },
        { field: 'qty', headerName: 'Cantidad', flex: 1 },
        { field: 'qtyproduct', headerName: 'Valor Producto', flex: 1 },
        { field: 'qtyMeli', headerName: 'Comisión Meli', flex: 1 },
        { field: 'qtyAmazon', headerName: 'Impuesto Amazon', flex: 1 },
        { field: 'qtySale', headerName: 'Impuesto Venta', flex: 1 },
        { field: 'qtyNational', headerName: 'Impuesto Nacional', flex: 1 },
        { field: 'shipping', headerName: 'Mercado Envíos', flex: 1 },
        { field: 'shippingInternational', headerName: 'Envío Internacional', flex: 1 },
        { field: 'utility', headerName: 'Utilidad', flex: 1 },
    ];

    const renderModal = (type) => (
        <Modal
            open={type === 'month' ? isMonthModalOpen : isYearModalOpen}
            onClose={() => (type === 'month' ? setMonthModalOpen(false) : setYearModalOpen(false))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: colors.primary[980],
                boxShadow: 24,
                p: 4,
                borderRadius: '10px',
            }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                    Seleccione {type === 'month' ? 'un Mes' : 'un Año'}
                </Typography>
                {type === 'month' ? (
                    <Box>
                        {getAvailableMonthsForYear(year).map((m) => (
                            <Button
                                key={m}
                                onClick={() => filterByMonth(m)}
                                sx={{ display: 'block', mb: 1, textAlign: 'left', width: '100%', color: colors.primary[100] }}
                            >
                                {m}
                            </Button>
                        ))}
                    </Box>
                ) : (
                    <Box>
                        {getAvailableYears().map((y) => (
                            <Button
                                key={y}
                                onClick={() => filterByYear(y)}
                                sx={{ display: 'block', mb: 1, textAlign: 'left', width: '100%', color: colors.primary[100] }}
                            >
                                {y}
                            </Button>
                        ))}
                    </Box>
                )}
            </Box>
        </Modal>
    );
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ padding: { xs: "10px", md: "20px" }, width: "95%", borderRadius: "20px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={5} >
                        <Box sx={{ textAlign: "center" }}>
                            <ShinyText text="Ventas / Compras" disabled={false} speed={3} margin={"0px 0px 20px 0px"} />
                        </Box>
                        <Box sx={{
                            backgroundColor: colors.primary[400],
                            padding: "20px",
                            borderRadius: "15px",
                            height: "300px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                        }}>
                            <LineChart results={filteredRows} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Box sx={{ textAlign: "center" }}>
                            <ShinyText text="Utilidad Generada" disabled={false} speed={3} margin={"0px 0px 20px 0px"} />
                        </Box>
                        <Box sx={{
                            backgroundColor: colors.primary[400],
                            padding: "20px",
                            borderRadius: "15px",
                            height: "300px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                        }}>
                            <DonutChart results={filteredRows} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box sx={{ textAlign: "center" }}>
                            <ShinyText text="Movimientos" disabled={false} speed={3} margin={"0px 0px 20px 0px"} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: 2,
                                borderRadius: "15px",
                                background: colors.primary[980],
                                height: "92px",
                                width: "100%",
                                marginBottom: "10px",
                                boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                                gap: 2
                            }}
                        >
                            <Box sx={{ display: "flex", justifyContent: "center", flex: 1 / 5 }}>
                                <AccountBalanceWalletIcon sx={{ width: "50px", height: "50px", bgcolor: "#0064FB", padding: "10px", borderRadius: "50%" }} />
                            </Box>
                            <Box sx={{ flex: 3 / 5 }}>
                                <Typography variant="h3" sx={{ fontWeight: "600" }}>${calculateTotalSales()}</Typography>
                                <Typography variant="h6" >Utilidad</Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", alignSelf: "flex-start", flex: 1 / 8 }}>
                                <Typography variant="body1" sx={{ color: '#2ECC71', fontWeight: 'bold' }}>
                                    40%
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: 2,
                                borderRadius: "15px",
                                background: colors.primary[980],
                                minHeight: "92px",
                                width: "100%",
                                marginBottom: "10px",
                                boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                                gap: 2
                            }}
                        >
                            <Box sx={{ display: "flex", justifyContent: "center", flex: 1 / 5 }}>
                                <CalendarMonthIcon sx={{ width: "50px", height: "50px", bgcolor: "#FF0000", padding: "10px", borderRadius: "50%" }} />
                            </Box>
                            <Box sx={{ flex: 3 / 5 }}>
                                <Typography variant="h3" sx={{ fontWeight: "600" }}>{month}</Typography>
                                <Typography variant="h6" >Mes</Typography>
                            </Box>
                            <Box sx={{ flex: 1 / 8 }}>
                                <GradientButton
                                    gradient="blue"
                                    onClick={() => setMonthModalOpen(true)}
                                    styles={{ width: "45px", height: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}
                                >
                                    <EditIcon sx={{ color: '#FFFFFF' }} />
                                </GradientButton>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: 2,
                                borderRadius: "15px",
                                background: colors.primary[980],
                                minHeight: "92px",
                                width: "100%",
                                boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                                gap: 2
                            }}
                        >
                            <Box sx={{ display: "flex", justifyContent: "center", flex: 1 / 5 }}>
                                <CalendarTodayIcon sx={{ width: "50px", height: "50px", bgcolor: "#2ecc71", padding: "10px", borderRadius: "50%" }} />
                            </Box>
                            <Box sx={{ flex: 3 / 5 }}>
                                <Typography variant="h3" sx={{ fontWeight: "600" }}>{year}</Typography>
                                <Typography variant="h6" >Año</Typography>
                            </Box>
                            <Box sx={{ flex: 1 / 8 }}>
                                <GradientButton
                                    gradient="blue"
                                    onClick={() => setYearModalOpen(true)}
                                    styles={{ width: "45px", height: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}
                                >
                                    <EditIcon sx={{ color: '#FFFFFF' }} />
                                </GradientButton>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <CustomDatagrid
                            rows={filteredRows}
                            columns={userColumns}
                            pageSize={5}
                        />

                    </Grid>
                </Grid>
                {renderModal('month')}
                {renderModal('year')}
            </Box>
        </Box >
    );
};

export default Results;
