import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { listProducts } from '../../../mockdata/Mockdata';
import Card from '../../Global/Card/Card';
import ShinyText from '../../Global/ShinyText/ShinyText';

const EditPost = ({ renderCard = 4 }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px" }}>
      <Box sx={{ padding: { xs: "0px", md: "20px" }, width: "100%", borderRadius: "20px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <ShinyText text="Productos Disponibles" disabled={false} speed={3} fontSize="25px" />
            <Grid container spacing={2} sx={{ mt: "8px" }}>
              {listProducts.map((product) => (
                <Grid item xs={6} md={4} lg={renderCard} key={product.sku}>
                  <Card
                    key={product.sku}
                    images={product.image}
                    title={product.title}
                    amazonPrice={product.price}
                    rating={"5"}
                    link={product.short_url}
                    buttonText="Modificar"
                    actionType="update"
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EditPost;
