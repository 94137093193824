import { useCallback, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./views/global/Topbar";
import Sidebar from "./views/global/Sidebar";
import Home from "./views/home/Home";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, tokens, useMode } from "./theme";
import Publisher from "./views/publisher/Publisher";
import Settings from "./views/settings/Settings";
import Shop from "./views/shop/Shop";
import Shipping from "./views/shipping/Shipping";
import Results from "./views/results/Results";
import Login from "./views/login/Login";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from 'react-router-dom';
import PrivateRoute from "./PrivateRoute";
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';

function App() {
  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
  const location = useLocation();
  const path = location?.pathname

  const handleSidebarToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const options = {
    particles: {
      number: {
        value: 80,
        density: {
          enable: true,
          area: 800
        }
      },
      color: {
        value: [colors.card[300]]
      },
      shape: {
        type: "star"
      },
      opacity: {
        value: { min: 0.3, max: 0.7 },
        random: true
      },
      size: {
        value: { min: 1, max: 2 },
        random: true
      },
      links: {
        enable: false
      },
      move: {
        enable: true,
        speed: 0.2,
        direction: "none",
        random: true,
        straight: false,
        outModes: "out"
      }
    },
    interactivity: {
      events: {
        onHover: {
          enable: false,
          mode: "trail"
        },
        onClick: {
          enable: false,
          mode: "push"
        }
      },
      modes: {
        repulse: {
          distance: 100
        },
        push: {
          quantity: 4
        }
      }
    }
  };


  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);


  const sidebarWidth = isCollapsed ? "80px" : "250px";

  useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          localStorage.setItem("id_token", token);
          localStorage.setItem("userEmail", user?.email);
        } catch (error) {
          console.error("Error obteniendo el token:", error);
        }
      }
    };

    fetchToken();
  }, [isAuthenticated, getAccessTokenSilently, user?.email]);


  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app" style={{ display: 'flex', minHeight: '100vh' }}>
          {path !== '/' ? (
            <>
              {isSidebar && (
                <Box
                  className="sidebar"
                  sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    height: '100vh',
                    width: sidebarWidth,
                    transition: 'width 0.3s',
                    zIndex: 1000,
                    display: { xs: "none", md: "flex" }
                  }}
                >
                  <Sidebar isCollapsed={isCollapsed} handleSidebarToggle={handleSidebarToggle} />
                </Box>

              )}
            </>
          ) : (<></>)}
          <Box
            className="content"
            sx={{
              marginLeft: path === '/' ? '0px' : { xs: "0px", md: sidebarWidth },
              flexGrow: 1,
              transition: 'margin-left 0.3s',
            }}
          >
            {path !== '/' && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: -1,
                }}
              >
                <Particles options={options} init={particlesInit} />
              </div>
            )}

            {path !== '/' ? (
              <>
                <div className="topbar">
                  <Topbar setIsSidebar={setIsSidebar} />
                </div>
              </>
            ) : (<></>)}

            <div className="main-content">
              <Routes>
                <Route
                  path="/"
                  element={isAuthenticated ? <Navigate to="/home" /> : <Login />}
                />
                <Route element={<PrivateRoute />}>
                  <Route path="/home" element={<Home />} />
                  <Route path="/publisher/:activepage" element={<Publisher />} />
                  <Route path="/settings/:activepage" element={<Settings />} />
                  <Route path="/shop/:activepage" element={<Shop />} />
                  <Route path="/shipping" element={<Shipping />} />
                  <Route path="/results" element={<Results />} />
                </Route>
              </Routes>
            </div>
          </Box>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
