import React from "react";
import "./GradientButton.css";

const GradientButton = ({ children, onClick, gradient, styles, ...props }) => {

  return (
    <button
      className={gradient=== "red" ? "gradient-button-red" : "gradient-button"}
      onClick={onClick}
      style={styles}
      {...props}
    >
      {children}
    </button>
  );
};

export default GradientButton;
