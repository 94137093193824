import Box from '@mui/material/Box';
import { Grid, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import { listProducts } from '../../../mockdata/Mockdata';
import ShinyText from '../../Global/ShinyText/ShinyText';
import GradientButton from '../../Global/GradientButton/GradientButton';
import CustomDatagrid from '../../Global/CustomDatagrid/CustomDatagrid';

const ProductBlocks = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    {
      field: 'sku',
      headerName: 'ID',
      flex: 1 / 2,
    },
    {
      field: 'image',
      headerName: 'Imagen',
      flex: 1 / 2,
      renderCell: (params) => (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "5px" }}>
          <Box sx={{
            width: 50,
            height: 40,
            borderRadius: '8px',
            bgcolor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #ddd',
            overflow: 'hidden',
          }}>
            <img src={params.value} alt="product" style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }} />
          </Box>
        </Box>
      ),
    },
    {
      field: 'title',
      headerName: 'Nombre',
      flex: 1,
    },
    {
      field: 'error',
      headerName: 'Error MercadoLibre',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Acción',
      flex: 1 / 2,
      renderCell: (params) => (
        <Box sx={{ alignSelf: "center", width: "80%", justifySelf: "center" }}>
          <GradientButton onClick={() => window.open(params.row.url, '_blank')}>
            Ver caso
          </GradientButton>
        </Box>
      ),
    }
  ];

  const rows = listProducts.map((product, index) => ({
    id: index,
    sku: product.sku,
    image: product.image,
    title: product.title,
    error: "Error de Contenido",
    url: product.url
  }));

  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
      bgcolor: colors.primary[400],
      borderRadius: "15px",
      padding: "15px"
    }}>
      <Box sx={{
        padding: { xs: "5px", md: "20px" },
        width: "100%",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
      }}>
        <Box sx={{ textAlign: "center" }}>
          <ShinyText text="Infracciones y Bloqueos de MercadoLibre" disabled={false} speed={3} fontSize="25px" />
        </Box>
        <Typography
          variant="h5"
          sx={{ mt: "15px", mb: "30px", textAlign: "center", color: colors.primary[100] }}
        >
          Productos con errores de publicación en MercadoLibre
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <CustomDatagrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProductBlocks;
