import Box from '@mui/material/Box';
import UpdateAccount from './UpdateAccount';

const ProfileSettings = () => {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', borderRadius: '15px', padding: '5px' }}>

      <Box sx={{ padding: { xs: '0px', md: '20px' }, width: '100%', borderRadius: '20px' }}>
        <UpdateAccount
          image={"/profile.jpg"}
          name={"Osneider Scarpetta"}
          city={"Bogotá D.C"}
          profile={"Osneider Scarpetta"}
        />
      </Box>
    </Box>
  );
};

export default ProfileSettings;
