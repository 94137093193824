const listProducts = [
    {
        "sku": "5755407",
        "number_part": "Z15S",
        "title": "MacBook Air de 13 pulgadas: Chip M2 de Apple con CPU de ocho nucleos y GPU de ocho nucleos, 256 GB SSD - Gris espacial 16GB",
        "price": "6700189.0000",
        "disponibility": "11",
        "detail": { "procesador": "M2", "almacenamiento": "256 GB SSD", "ram": "16 GB", "pantalla": "13in" },
        "category_name": "Computadores Portátiles",
        "brand_name": "APPLE",
        "image": "https://http2.mlstatic.com/D_NQ_NP_801112-MLA46516512347_062021-V.webp",
        "shipping_time": 5
    },
    {
        "sku": "4462693",
        "number_part": "3UR69AA#ABM",
        "title": "PC Portatil HP 24-F008La 23,8 NT, Ci3, 4GB, 1TB, NoDVD, Win10, Negra",
        "price": "1945888.0000",
        "disponibility": "12",
        "detail": { "procesador": "Intel Core i3", "almacenamiento": "1 TB HDD", "ram": "4 GB", "pantalla": "23,8in" },
        "category_name": "Computadores Portátiles",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_856676-MCO70805083375_072023-V.webp",
        "shipping_time": 7
    },
    {
        "sku": "4466030",
        "number_part": "4PF37LA#ABM",
        "title": "PC Portatil HP 14-CK0033LA COREI5-7200U 8GB 1TB 14IN FREEDOS COLOR-BLUE",
        "price": "1732293.0000",
        "disponibility": "12",
        "detail": { "procesador": "Intel Core i5", "almacenamiento": "1 TB HDD", "ram": "8 GB", "pantalla": "14in" },
        "category_name": "Computadores Portátiles",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_728808-MCO70856135694_082023-V.webp",
        "shipping_time": 6
    },
    {
        "sku": "4496749",
        "number_part": "6WV37LT#ABM",
        "title": "HP ProBook 430 G6, IntelAR Core# i5-8265U, WindowsAR 10 Professional 64bit 8GB DDR4, SSD 256GB",
        "price": "3569209.0000",
        "disponibility": "12",
        "detail": { "procesador": "Intel Core i5", "almacenamiento": "256 GB SSD", "ram": "8 GB", "pantalla": "14in" },
        "category_name": "Computadores Portátiles",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_753832-MCO43109945791_082020-V.webp",
        "shipping_time": 8
    },
    {
        "sku": "4805051",
        "number_part": "4PG17LA#ABM",
        "title": "PC Portatil HP 14-ck0040la i3 4GB 1TB 14 Linux Negro",
        "price": "1329628.0000",
        "disponibility": "12",
        "detail": { "procesador": "Intel Core i3", "almacenamiento": "1 TB HDD", "ram": "4 GB", "pantalla": "14in" },
        "category_name": "Computadores Portátiles",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_937021-MCO43248628990_082020-V.webp",
        "shipping_time": 4
    },
    {
        "sku": "4829872",
        "number_part": "3J042LT#ABM",
        "title": "Portatil HP ProBook 450 G7, i7-10510U, Win10P, 8GB, Video MX130 2GB, SSD 512GB, 15.6, 1/1/0",
        "price": "3528000.0000",
        "disponibility": "12",
        "detail": { "procesador": "Intel Core i7", "almacenamiento": "512 GB SSD", "ram": "8 GB", "pantalla": "15,6in" },
        "category_name": "Computadores Portátiles",
        "brand_name": "HP",
        "image": "https://microcell.co/26522-medium_default/portatil-hp-probook-450-g7-core-i7-10510u-8gb-512gb-ssd-156-video-2gb-w10p.jpg",
        "shipping_time": 6
    },
    {
        "sku": "4829873",
        "number_part": "3J342LT#ABM",
        "title": "Portatil HP ProBook 440 G7, i7-10510U, Win10P, 8GB, HDD 1TB, 14, 1/1/0",
        "price": "3530827.0000",
        "disponibility": "12",
        "detail": { "procesador": "Intel Core i7", "almacenamiento": "1 TB HDD", "ram": "8 GB", "pantalla": "14in" },
        "category_name": "Computadores Portátiles",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_929407-MCO70535817864_072023-V.webp",
        "shipping_time": 9
    },
    {
        "sku": "5615451",
        "number_part": "1Z978AA#ABA",
        "title": "28.0IN UHD 60Hz 5ms DP HDMI USB-C VESA Height Adjustable",
        "price": "2325451.0000",
        "disponibility": "12",
        "detail": { "tamaño": "28in", "resolucion": "3840x2160", "calidad": "UHD", "entrada": "HDMI Y USB-C" },
        "category_name": "Monitores y Pantallas",
        "brand_name": "HP",
        "image": "https://i0.wp.com/www.inovamusicnet.com/wp-content/uploads/2023/01/71J8MZHhiQL.AC_SL600_.jpg?fit=600%2C409&ssl=1",
        "shipping_time": 3
    },
    {
        "sku": "5615452",
        "number_part": "22J05AA#ABA",
        "title": "24.5IN Res. FHD (1920 x 1080) Tasa 165 Hz 1MS Color Negro",
        "price": "1195384.0000",
        "disponibility": "8",
        "detail": { "tamaño": "24,5in", "resolucion": "1920x1080", "calidad": "FHD", "entrada": "HDMI" },
        "category_name": "Monitores y Pantallas",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_610431-MLA54052812183_022023-O.webp",
        "shipping_time": 6
    },
    {
        "sku": "5615453",
        "number_part": "25Y22AA#ABA",
        "title": "18.5IN HD 60Hz 5ms HDMI VGA VESA -Color Negro",
        "price": "522938.0000",
        "disponibility": "8",
        "detail": { "tamaño": "18,5in", "resolucion": "1920x1080", "calidad": "FHD", "entrada": "HDMI Y VGA" },
        "category_name": "Monitores y Pantallas",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_687018-MLA44063922326_112020-O.webp",
        "shipping_time": 4
    },
    {
        "sku": "5615454",
        "number_part": "2H2K2AA#ABA",
        "title": "21.5IN Res. FHD (1920 x 1080) Tasa 75 Hz 5MS HDMI VGA Color Negro",
        "price": "730625.0000",
        "disponibility": "8",
        "detail": { "tamaño": "21,5in", "resolucion": "1920x1080", "calidad": "FHD", "entrada": "HDMI Y VGA" },
        "category_name": "Monitores y Pantallas",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_801112-MLA46516512347_062021-V.webp",
        "shipping_time": 7
    },
    {
        "sku": "5615455",
        "number_part": "2V6B5AA#ABA",
        "title": "24.5IN Res. FHD (1920 x 1080) Tasa 144 Hz 1MS HDMI Color Negro",
        "price": "1835390.0000",
        "disponibility": "8",
        "detail": { "tamaño": "24,5in", "resolucion": "1920x1080", "calidad": "FHD", "entrada": "HDMI" },
        "category_name": "Monitores y Pantallas",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_862965-MCO54833228087_042023-V.webp",
        "shipping_time": 5
    },
]

const sellers = [
    { name: "Osneider Scarpetta", total: "103M", image: "/top.jpg" },
    { name: "David Acevedo", total: "92M", image: "/top2.jpeg" },
    { name: "Carlos Gallego", total: "91M", image: "/top3.jpeg" },
    { name: "Ana Martínez", total: "70M", image: "/profile.jpg" },
    { name: "Elena González", total: "67M", image: "/profile.jpg" },
    { name: "Miguel Sánchez", total: "65M", image: "/profile.jpg" },
    { name: "Laura Fernández", total: "59M", image: "/profile.jpg" },
    { name: "Sofía Ramírez", total: "55M", image: "/profile.jpg" }
];

const questions = [
    {
        question: "Hola, ¿cuándo me llega el pedido 5755407?",
        image: "https://http2.mlstatic.com/D_NQ_NP_801112-MLA46516512347_062021-V.webp",
        title: "MacBook Air de 13 pulgadas: Chip M2 de Apple...",
        price: "6.700.189",
        user: "JUAN123",
        isCustomer: true
    },
    {
        question: "Quisiera saber si el modelo 4PF37LA#ABM tiene garantía extendida.",
        image: "https://http2.mlstatic.com/D_NQ_NP_728808-MCO70856135694_082023-V.webp",
        title: "PC Portatil HP 14-CK0033LA COREI5-7200U...",
        price: "1.732.293",
        user: "MARIA_GH",
        isCustomer: true
    },
    {
        question: "¿El pedido 5615451 incluye cable HDMI?",
        image: "https://i0.wp.com/www.inovamusicnet.com/wp-content/uploads/2023/01/71J8MZHhiQL.AC_SL600_.jpg?fit=600%2C409&ssl=1",
        title: "28.0IN UHD 60Hz 5ms DP HDMI USB-C...",
        price: "2.325.451",
        user: "LUIS_FERNANDO",
        isCustomer: true
    },
    {
        question: "¿Puedo recoger el pedido 5615455 en tienda física?",
        image: "https://http2.mlstatic.com/D_NQ_NP_862965-MCO54833228087_042023-V.webp",
        title: "24.5IN Res. FHD (1920 x 1080)...",
        price: "1.835.390",
        user: "KAREN_LOPEZ",
        isCustomer: true
    },
    {
        question: "¿El modelo 4829873 tiene entrega el mismo día?",
        image: "https://http2.mlstatic.com/D_NQ_NP_929407-MCO70535817864_072023-V.webp",
        title: "Portatil HP ProBook 440 G7, i7-10510U...",
        price: "3.530.827",
        user: "DIEGO_OP",
        isCustomer: true
    },
    {
        question: "Buen día, ¿tienen este modelo en otros colores?",
        image: "https://http2.mlstatic.com/D_NQ_NP_856676-MCO70805083375_072023-V.webp",
        title: "PC Portatil HP 24-F008La 23,8 NT...",
        price: "1.945.888",
        user: "JOSE_MARTINEZ",
        isCustomer: false
    },
    {
        question: "Hola, ¿tienen disponibilidad en almacén físico?",
        image: "https://microcell.co/26522-medium_default/portatil-hp-probook-450-g7-core-i7-10510u-8gb-512gb-ssd-156-video-2gb-w10p.jpg",
        title: "Portatil HP ProBook 450 G7, i7-10510U...",
        price: "3.528.000",
        user: "CAMILA_GA",
        isCustomer: false
    },
    {
        question: "¿El precio incluye impuestos?",
        image: "https://http2.mlstatic.com/D_NQ_NP_610431-MLA54052812183_022023-O.webp",
        title: "24.5IN Res. FHD (1920 x 1080)...",
        price: "1.195.384",
        user: "FERNANDO78",
        isCustomer: false
    },
    {
        question: "¿Hay descuento por comprar más de dos unidades?",
        image: "https://http2.mlstatic.com/D_NQ_NP_937021-MCO43248628990_082020-V.webp",
        title: "PC Portatil HP 14-ck0040la i3 4GB...",
        price: "1.329.628",
        user: "ANA_LP",
        isCustomer: false
    },
    {
        question: "¿Ofrecen crédito para este modelo?",
        image: "https://http2.mlstatic.com/D_NQ_NP_753832-MCO43109945791_082020-V.webp",
        title: "HP ProBook 430 G6, IntelAR Core# i5-8265U...",
        price: "3.569.209",
        user: "ROBERTO_GOMEZ",
        isCustomer: false
    }
];

const sampleRows = [
    // Junio 2024
    {
        id: 1,
        date: '2024-06-05',
        title: 'Laptop Dell Inspiron 15',
        id_venta: '1',
        id_meli: '101',
        sku: 'DINS15',
        qty: '120',
        qtyproduct: '600',
        qtyMeli: '300',
        qtyAmazon: '250',
        qtySale: '650',
        qtyNational: '200',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '400000',
    },
    {
        id: 2,
        date: '2024-06-10',
        title: 'Auriculares Sony WH-CH720N',
        id_venta: '2',
        id_meli: '102',
        sku: 'WHCH720N',
        qty: '90',
        qtyproduct: '450',
        qtyMeli: '250',
        qtyAmazon: '180',
        qtySale: '500',
        qtyNational: '160',
        shipping: 'Meli',
        shippingInternational: 'Colombia',
        utility: '350000',
    },
    {
        id: 3,
        date: '2024-06-15',
        title: 'Smartphone OnePlus 11',
        id_venta: '3',
        id_meli: '103',
        sku: 'OPL11',
        qty: '75',
        qtyproduct: '400',
        qtyMeli: '220',
        qtyAmazon: '150',
        qtySale: '430',
        qtyNational: '140',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '450000',
    },
    {
        id: 4,
        date: '2024-06-20',
        title: 'Monitor BenQ EX3501R',
        id_venta: '4',
        id_meli: '104',
        sku: 'BNQEX35',
        qty: '40',
        qtyproduct: '200',
        qtyMeli: '100',
        qtyAmazon: '80',
        qtySale: '220',
        qtyNational: '60',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '300000',
    },
    {
        id: 5,
        date: '2024-06-25',
        title: 'Router ASUS AX88U',
        id_venta: '5',
        id_meli: '105',
        sku: 'ASAX88U',
        qty: '65',
        qtyproduct: '350',
        qtyMeli: '180',
        qtyAmazon: '130',
        qtySale: '390',
        qtyNational: '120',
        shipping: 'Meli',
        shippingInternational: 'Colombia',
        utility: '380000',
    },

    // Julio 2024
    {
        id: 6,
        date: '2024-07-03',
        title: 'Tablet Apple iPad Air',
        id_venta: '6',
        id_meli: '106',
        sku: 'IPDAIR',
        qty: '80',
        qtyproduct: '400',
        qtyMeli: '220',
        qtyAmazon: '160',
        qtySale: '430',
        qtyNational: '140',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '420000',
    },
    {
        id: 7,
        date: '2024-07-08',
        title: 'Teclado Mecánico HyperX Alloy FPS',
        id_venta: '7',
        id_meli: '107',
        sku: 'HYPXFPS',
        qty: '95',
        qtyproduct: '500',
        qtyMeli: '270',
        qtyAmazon: '190',
        qtySale: '520',
        qtyNational: '170',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '320000',
    },
    {
        id: 8,
        date: '2024-07-15',
        title: 'Cámara Sony Alpha 7 IV',
        id_venta: '8',
        id_meli: '108',
        sku: 'SAA7IV',
        qty: '50',
        qtyproduct: '250',
        qtyMeli: '130',
        qtyAmazon: '100',
        qtySale: '280',
        qtyNational: '90',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '650000',
    },
    {
        id: 9,
        date: '2024-07-20',
        title: 'Disco Duro Externo Western Digital 5TB',
        id_venta: '9',
        id_meli: '109',
        sku: 'WD5TB',
        qty: '120',
        qtyproduct: '600',
        qtyMeli: '300',
        qtyAmazon: '240',
        qtySale: '650',
        qtyNational: '200',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '350000',
    },
    {
        id: 10,
        date: '2024-07-30',
        title: 'Consola Nintendo Switch OLED',
        id_venta: '10',
        id_meli: '110',
        sku: 'NSWOL',
        qty: '75',
        qtyproduct: '400',
        qtyMeli: '220',
        qtyAmazon: '160',
        qtySale: '430',
        qtyNational: '140',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '400000',
    },

    // Agosto 2024
    {
        id: 11,
        date: '2024-08-02',
        title: 'Smartwatch Garmin Fenix 7',
        id_venta: '11',
        id_meli: '111',
        sku: 'GARF7',
        qty: '60',
        qtyproduct: '300',
        qtyMeli: '150',
        qtyAmazon: '120',
        qtySale: '320',
        qtyNational: '100',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '450000',
    },
    {
        id: 12,
        date: '2024-08-10',
        title: 'Smartphone Google Pixel 7',
        id_venta: '12',
        id_meli: '112',
        sku: 'GPXL7',
        qty: '90',
        qtyproduct: '450',
        qtyMeli: '260',
        qtyAmazon: '170',
        qtySale: '500',
        qtyNational: '160',
        shipping: 'Meli',
        shippingInternational: 'Colombia',
        utility: '520000',
    },
    {
        id: 13,
        date: '2024-08-15',
        title: 'Monitor LG UltraGear 27"',
        id_venta: '13',
        id_meli: '113',
        sku: 'LGUG27',
        qty: '100',
        qtyproduct: '500',
        qtyMeli: '270',
        qtyAmazon: '190',
        qtySale: '550',
        qtyNational: '180',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '600000',
    },
    {
        id: 14,
        date: '2024-08-20',
        title: 'Mouse Logitech G502 Hero',
        id_venta: '14',
        id_meli: '114',
        sku: 'LOG502',
        qty: '75',
        qtyproduct: '375',
        qtyMeli: '200',
        qtyAmazon: '150',
        qtySale: '400',
        qtyNational: '130',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '230000',
    },
    {
        id: 15,
        date: '2024-08-29',
        title: 'Tarjeta Gráfica AMD Radeon RX 6800',
        id_venta: '15',
        id_meli: '115',
        sku: 'AMDRX68',
        qty: '55',
        qtyproduct: '275',
        qtyMeli: '150',
        qtyAmazon: '100',
        qtySale: '300',
        qtyNational: '110',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '700000',
    },
    {
        id: 16,
        date: '2024-09-05',
        title: 'Laptop MacBook Pro 13"',
        id_venta: '16',
        id_meli: '116',
        sku: 'MBP13',
        qty: '70',
        qtyproduct: '350',
        qtyMeli: '180',
        qtyAmazon: '130',
        qtySale: '380',
        qtyNational: '120',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '800000',
    },
    {
        id: 17,
        date: '2024-09-10',
        title: 'Smartphone Samsung Galaxy S23',
        id_venta: '17',
        id_meli: '117',
        sku: 'SGS23',
        qty: '100',
        qtyproduct: '500',
        qtyMeli: '250',
        qtyAmazon: '180',
        qtySale: '600',
        qtyNational: '170',
        shipping: 'Meli',
        shippingInternational: 'Colombia',
        utility: '600000',
    },
    {
        id: 18,
        date: '2024-09-15',
        title: 'Bocina JBL Flip 5',
        id_venta: '18',
        id_meli: '118',
        sku: 'JBLF5',
        qty: '80',
        qtyproduct: '400',
        qtyMeli: '220',
        qtyAmazon: '160',
        qtySale: '450',
        qtyNational: '140',
        shipping: 'Meli',
        shippingInternational: 'Colombia',
        utility: '350000',
    },
    {
        id: 19,
        date: '2024-09-20',
        title: 'Impresora HP LaserJet Pro M404n',
        id_venta: '19',
        id_meli: '119',
        sku: 'HPLJ404N',
        qty: '60',
        qtyproduct: '300',
        qtyMeli: '150',
        qtyAmazon: '110',
        qtySale: '280',
        qtyNational: '90',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '250000',
    },
    {
        id: 20,
        date: '2024-09-30',
        title: 'Auriculares Bose QuietComfort 45',
        id_venta: '20',
        id_meli: '120',
        sku: 'BOSEQC45',
        qty: '50',
        qtyproduct: '250',
        qtyMeli: '130',
        qtyAmazon: '100',
        qtySale: '280',
        qtyNational: '90',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '500000',
    },

    // Octubre 2024
    {
        id: 21,
        date: '2024-10-03',
        title: 'Tablet Samsung Galaxy Tab S8',
        id_venta: '21',
        id_meli: '121',
        sku: 'SGT8',
        qty: '90',
        qtyproduct: '450',
        qtyMeli: '230',
        qtyAmazon: '160',
        qtySale: '500',
        qtyNational: '170',
        shipping: 'Meli',
        shippingInternational: 'Colombia',
        utility: '400000',
    },
    {
        id: 22,
        date: '2024-10-08',
        title: 'Teclado Logitech MX Keys',
        id_venta: '22',
        id_meli: '122',
        sku: 'LOGMXK',
        qty: '100',
        qtyproduct: '500',
        qtyMeli: '270',
        qtyAmazon: '190',
        qtySale: '550',
        qtyNational: '180',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '250000',
    },
    {
        id: 23,
        date: '2024-10-12',
        title: 'Cámara Fujifilm X-T4',
        id_venta: '23',
        id_meli: '123',
        sku: 'FJXT4',
        qty: '40',
        qtyproduct: '200',
        qtyMeli: '100',
        qtyAmazon: '80',
        qtySale: '220',
        qtyNational: '60',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '700000',
    },
    {
        id: 24,
        date: '2024-10-20',
        title: 'Reloj Garmin Forerunner 945',
        id_venta: '24',
        id_meli: '124',
        sku: 'GAR945',
        qty: '70',
        qtyproduct: '350',
        qtyMeli: '190',
        qtyAmazon: '140',
        qtySale: '380',
        qtyNational: '120',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '400000',
    },
    {
        id: 25,
        date: '2024-10-30',
        title: 'Consola PlayStation 5',
        id_venta: '25',
        id_meli: '125',
        sku: 'PS5',
        qty: '55',
        qtyproduct: '275',
        qtyMeli: '140',
        qtyAmazon: '120',
        qtySale: '270',
        qtyNational: '90',
        shipping: 'Meli',
        shippingInternational: 'Colombia',
        utility: '600000',
    },

    // Noviembre 2024
    {
        id: 26,
        date: '2024-11-03',
        title: 'Smartphone Xiaomi Mi 11',
        id_venta: '26',
        id_meli: '126',
        sku: 'XMIM11',
        qty: '85',
        qtyproduct: '425',
        qtyMeli: '230',
        qtyAmazon: '170',
        qtySale: '500',
        qtyNational: '160',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '550000',
    },
    {
        id: 27,
        date: '2024-11-08',
        title: 'Auriculares Sennheiser Momentum 4',
        id_venta: '27',
        id_meli: '127',
        sku: 'SENMM4',
        qty: '60',
        qtyproduct: '300',
        qtyMeli: '150',
        qtyAmazon: '120',
        qtySale: '280',
        qtyNational: '90',
        shipping: 'Meli',
        shippingInternational: 'Colombia',
        utility: '450000',
    },
    {
        id: 28,
        date: '2024-11-15',
        title: 'Tablet Microsoft Surface Pro 8',
        id_venta: '28',
        id_meli: '128',
        sku: 'MSSP8',
        qty: '95',
        qtyproduct: '475',
        qtyMeli: '250',
        qtyAmazon: '190',
        qtySale: '550',
        qtyNational: '180',
        shipping: 'Meli',
        shippingInternational: 'Colombia',
        utility: '500000',
    },
    {
        id: 29,
        date: '2024-11-25',
        title: 'Reloj Samsung Galaxy Watch 5',
        id_venta: '29',
        id_meli: '129',
        sku: 'SGW5',
        qty: '70',
        qtyproduct: '350',
        qtyMeli: '180',
        qtyAmazon: '150',
        qtySale: '400',
        qtyNational: '130',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '400000',
    },
    {
        id: 30,
        date: '2024-11-30',
        title: 'Auriculares Beats Studio 3',
        id_venta: '30',
        id_meli: '130',
        sku: 'BEATST3',
        qty: '45',
        qtyproduct: '225',
        qtyMeli: '120',
        qtyAmazon: '90',
        qtySale: '220',
        qtyNational: '70',
        shipping: 'Meli',
        shippingInternational: 'Colombia',
        utility: '350000',
    },

    // Diciembre 2024
    {
        id: 31,
        date: '2024-12-02',
        title: 'Smartphone iPhone 15 Pro',
        id_venta: '31',
        id_meli: '131',
        sku: 'IPH15P',
        qty: '50',
        qtyproduct: '250',
        qtyMeli: '130',
        qtyAmazon: '100',
        qtySale: '280',
        qtyNational: '90',
        shipping: 'Meli',
        shippingInternational: 'Colombia',
        utility: '750000',
    },
    {
        id: 32,
        date: '2024-12-10',
        title: 'Laptop ASUS ROG Zephyrus G14',
        id_venta: '32',
        id_meli: '132',
        sku: 'ASUSROG14',
        qty: '60',
        qtyproduct: '300',
        qtyMeli: '160',
        qtyAmazon: '120',
        qtySale: '280',
        qtyNational: '90',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '900000',
    },
    {
        id: 33,
        date: '2024-12-15',
        title: 'Reloj Apple Watch Series 8',
        id_venta: '33',
        id_meli: '133',
        sku: 'AW8',
        qty: '80',
        qtyproduct: '400',
        qtyMeli: '220',
        qtyAmazon: '160',
        qtySale: '450',
        qtyNational: '140',
        shipping: 'Meli',
        shippingInternational: 'Colombia',
        utility: '600000',
    },
    {
        id: 34,
        date: '2024-12-20',
        title: 'Cámara Canon EOS R6',
        id_venta: '34',
        id_meli: '134',
        sku: 'CNRE6',
        qty: '70',
        qtyproduct: '350',
        qtyMeli: '180',
        qtyAmazon: '130',
        qtySale: '320',
        qtyNational: '110',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '800000',
    },
    {
        id: 35,
        date: '2024-12-31',
        title: 'Consola Xbox Series X',
        id_venta: '35',
        id_meli: '135',
        sku: 'XBOXSX',
        qty: '85',
        qtyproduct: '425',
        qtyMeli: '220',
        qtyAmazon: '180',
        qtySale: '500',
        qtyNational: '170',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '700000',
    },

    // Enero 2025
    {
        id: 36,
        date: '2025-01-05',
        title: 'Auriculares Sony WH-1000XM5',
        id_venta: '36',
        id_meli: '136',
        sku: 'SONYWHXM5',
        qty: '65',
        qtyproduct: '325',
        qtyMeli: '180',
        qtyAmazon: '140',
        qtySale: '350',
        qtyNational: '120',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '600000',
    },
    {
        id: 37,
        date: '2025-01-10',
        title: 'Laptop HP Spectre x360',
        id_venta: '37',
        id_meli: '137',
        sku: 'HPSP360',
        qty: '55',
        qtyproduct: '275',
        qtyMeli: '140',
        qtyAmazon: '110',
        qtySale: '250',
        qtyNational: '90',
        shipping: 'Meli',
        shippingInternational: 'Colombia',
        utility: '750000',
    },
    {
        id: 38,
        date: '2025-01-15',
        title: 'Smartphone Oppo Find X5 Pro',
        id_venta: '38',
        id_meli: '138',
        sku: 'OPFX5P',
        qty: '60',
        qtyproduct: '300',
        qtyMeli: '160',
        qtyAmazon: '120',
        qtySale: '280',
        qtyNational: '100',
        shipping: 'Meli',
        shippingInternational: 'Colombia',
        utility: '550000',
    },
    {
        id: 39,
        date: '2025-01-20',
        title: 'Teclado Corsair K95 RGB',
        id_venta: '39',
        id_meli: '139',
        sku: 'COR95RGB',
        qty: '50',
        qtyproduct: '250',
        qtyMeli: '120',
        qtyAmazon: '90',
        qtySale: '220',
        qtyNational: '80',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '400000',
    },
    {
        id: 40,
        date: '2025-01-30',
        title: 'Consola Nintendo Switch',
        id_venta: '40',
        id_meli: '140',
        sku: 'NSWITCH',
        qty: '45',
        qtyproduct: '225',
        qtyMeli: '120',
        qtyAmazon: '90',
        qtySale: '200',
        qtyNational: '70',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '550000',
    }
];


const cardsData = [
    {
        order: '1284765623467365',
        title: 'MacBook Air 13"',
        price: '6´700.189',
        image: 'https://http2.mlstatic.com/D_NQ_NP_801112-MLA46516512347_062021-V.webp',
        track: '54387235432',
        status: 'Pagada',
        tracking: 'No Registrada',
        download: false,
    },
    {
        order: '7364598345629384',
        title: 'PC Portátil HP 24-F008La',
        price: '1´945.888',
        image: 'https://http2.mlstatic.com/D_NQ_NP_856676-MCO70805083375_072023-V.webp',
        track: '90876345211',
        status: 'En Proceso',
        tracking: 'No Registrada',
        download: true,
    },
    {
        order: '3248756342357563',
        title: 'HP ProBook 430 G6',
        price: '3´569.209',
        image: 'https://http2.mlstatic.com/D_NQ_NP_753832-MCO43109945791_082020-V.webp',
        track: '67854312976',
        status: 'Pagada',
        tracking: 'AMZ567894',
        download: false,
    },
    {
        order: '9987542312345567',
        title: 'PC Portátil HP 14-ck0040la',
        price: '1´329.628',
        image: 'https://http2.mlstatic.com/D_NQ_NP_937021-MCO43248628990_082020-V.webp',
        track: '43527898223',
        status: 'Enviada',
        tracking: 'DHL783219',
        download: false,
    },
    {
        order: '5678324523458953',
        title: 'Portátil HP ProBook 450 G7',
        price: '3´528.000',
        image: 'https://microcell.co/26522-medium_default/portatil-hp-probook-450-g7-core-i7-10510u-8gb-512gb-ssd-156-video-2gb-w10p.jpg',
        track: '34789234567',
        status: 'En Proceso',
        tracking: 'No Registrada',
        download: true,
    },
    {
        order: '8321567345893241',
        title: 'Monitor HP 28.0IN UHD',
        price: '2´325.451',
        image: 'https://i0.wp.com/www.inovamusicnet.com/wp-content/uploads/2023/01/71J8MZHhiQL.AC_SL600_.jpg?fit=600%2C409&ssl=1',
        track: '21543876542',
        status: 'Pagada',
        tracking: 'UPS345783',
        download: true,
    },
    {
        order: '2345897645213789',
        title: 'Monitor HP 24.5IN FHD',
        price: '1´195.384',
        image: 'https://http2.mlstatic.com/D_NQ_NP_610431-MLA54052812183_022023-O.webp',
        track: '98456341237',
        status: 'Cancelada',
        tracking: 'No Registrada',
        download: false,
    },
    {
        order: '7863421896543872',
        title: 'Monitor HP 21.5IN FHD',
        price: '730.625',
        image: 'https://http2.mlstatic.com/D_NQ_NP_801112-MLA46516512347_062021-V.webp',
        track: '54837298561',
        status: 'En Proceso',
        tracking: 'DHL987654',
        download: false,
    },
    {
        order: '3124579821435769',
        title: 'Monitor HP 18.5IN HD',
        price: '522.938',
        image: 'https://http2.mlstatic.com/D_NQ_NP_687018-MLA44063922326_112020-O.webp',
        track: '98432576219',
        status: 'Enviada',
        tracking: 'AMZ123456',
        download: false,
    },
    {
        order: '4357289854123768',
        title: 'Monitor HP 24.5IN FHD 144Hz',
        price: '1´835.390',
        image: 'https://http2.mlstatic.com/D_NQ_NP_862965-MCO54833228087_042023-V.webp',
        track: '14325798432',
        status: 'Pagada',
        tracking: 'UPS987123',
        download: true,
    },
];

const mockCategories = [
    {
      id: "MLA1000", 
      name: "Hogar", 
      subcategories: [
        {
          id: "MLA1050", 
          name: "Muebles", 
          subcategories: [
            { name: "Sofás", productCount: 5000 },
            { name: "Mesas", productCount: 3000 },
            { name: "Sillas", productCount: 4000 },
          ]
        },
        {
          id: "MLA1001", 
          name: "Decoración", 
          subcategories: [
            { name: "Cuadros", productCount: 2500 },
            { name: "Lámparas", productCount: 3000 },
            { name: "Alfombras", productCount: 2000 },
          ]
        },
        {
          id: "MLA1002", 
          name: "Electrodomésticos", 
          subcategories: [
            { name: "Refrigeradores", productCount: 3500 },
            { name: "Licuadoras", productCount: 4000 },
            { name: "Microondas", productCount: 3000 },
          ]
        },
      ]
    },
    {
      id: "MLA1003",
      name: "Electrónica",
      subcategories: [
        {
          id: "MLA1055",
          name: "Celulares y Smartphones",
          subcategories: [
            { name: "Apple", productCount: 12000 },
            { name: "Samsung", productCount: 10000 },
            { name: "Xiaomi", productCount: 8000 },
          ]
        },
        {
          id: "MLA1004",
          name: "Computación",
          subcategories: [
            { name: "Laptops", productCount: 15000 },
            { name: "PCs de Escritorio", productCount: 8000 },
            { name: "Accesorios", productCount: 4000 },
          ]
        },
        {
          id: "MLA1005",
          name: "Audio",
          subcategories: [
            { name: "Auriculares", productCount: 5000 },
            { name: "Altavoces", productCount: 6000 },
            { name: "Bocinas Bluetooth", productCount: 4500 },
          ]
        },
      ]
    },
    {
      id: "MLA1006",
      name: "Ropa y Accesorios",
      subcategories: [
        {
          id: "MLA1211",
          name: "Ropa de Mujer",
          subcategories: [
            { name: "Vestidos", productCount: 9000 },
            { name: "Blusas", productCount: 6000 },
            { name: "Faldas", productCount: 4000 },
          ]
        },
        {
          id: "MLA1212",
          name: "Ropa de Hombre",
          subcategories: [
            { name: "Camisas", productCount: 8000 },
            { name: "Jeans", productCount: 5000 },
            { name: "Chaquetas", productCount: 3000 },
          ]
        },
        {
          id: "MLA1213",
          name: "Accesorios",
          subcategories: [
            { name: "Gorros", productCount: 2500 },
            { name: "Cinturones", productCount: 2000 },
            { name: "Carteras", productCount: 4000 },
          ]
        },
      ]
    },
    {
      id: "MLA1214",
      name: "Deportes y Fitness",
      subcategories: [
        {
          id: "MLA1348",
          name: "Futbol",
          subcategories: [
            { name: "Balones", productCount: 8000 },
            { name: "Camisetas", productCount: 5000 },
            { name: "Botines", productCount: 6000 },
          ]
        },
        {
          id: "MLA1349",
          name: "Running",
          subcategories: [
            { name: "Zapatillas", productCount: 7000 },
            { name: "Ropa Deportiva", productCount: 4000 },
            { name: "Accesorios", productCount: 3000 },
          ]
        },
        {
          id: "MLA1350",
          name: "Ciclismo",
          subcategories: [
            { name: "Bicicletas", productCount: 12000 },
            { name: "Accesorios para Bicicletas", productCount: 8000 },
            { name: "Casco", productCount: 3000 },
          ]
        },
      ]
    },
    {
      id: "MLA1007",
      name: "Juguetes y Bebés",
      subcategories: [
        {
          id: "MLA1351",
          name: "Juguetes para Niños",
          subcategories: [
            { name: "Juguetes de Plástico", productCount: 4000 },
            { name: "Peluches", productCount: 3000 },
            { name: "Juguetes de Madera", productCount: 2000 },
          ]
        },
        {
          id: "MLA1352",
          name: "Ropa para Bebés",
          subcategories: [
            { name: "Body y Pijamas", productCount: 5000 },
            { name: "Zapatos para Bebé", productCount: 2000 },
          ]
        },
        {
          id: "MLA1353",
          name: "Cochecitos",
          subcategories: [
            { name: "Cochecitos de Viaje", productCount: 3000 },
            { name: "Cochecitos para Bebés", productCount: 1500 },
          ]
        },
      ]
    },
  ];
  


module.exports = { listProducts, sellers, questions, sampleRows, cardsData, mockCategories };
