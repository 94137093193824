import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title
} from 'chart.js';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const DonutChart = ({ results = [] }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  if (results.length === 0) {
    return <div>No hay datos disponibles</div>;
  }

  const totalSales = results.reduce((acc, item) => {
    const qtySale = parseFloat(item.qtySale);
    return acc + (isNaN(qtySale) ? 0 : qtySale);
  }, 0);

  const totalUtility = results.reduce((acc, item) => {
    const utility = parseFloat(item.utility);
    return acc + (isNaN(utility) ? 0 : utility);
  }, 0);

  const data = {
    labels: ['Ventas', 'Utilidad'],
    datasets: [
      {
        label: 'Utilidad Generada',
        data: [totalSales, totalUtility],
        backgroundColor: ['#FFFFFF', '#0064FB'],
        borderColor: ['#0064FB', '#0064FB'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: colors.primary[100],
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: false,
        text: 'Gráfico de Dona - Utilidad Generada',
        color: '#333',
        font: {
          size: 18,
        },
      },
      tooltip: {
        bodyColor: '#FFFFFF',
        titleColor: '#FFFFFF',
      },
    },
  };

  return <Doughnut data={data} options={options} />;
};

export default DonutChart;
