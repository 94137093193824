import React from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import { useDropzone } from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import GradientButton from '../../Global/GradientButton/GradientButton';
import ShinyText from '../../Global/ShinyText/ShinyText';

const CSVPublisher = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const onDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px", bgcolor: colors.primary[400], borderRadius: "15px", padding: "15px" }}>
      <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "95%", borderRadius: "20px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2} sx={{ display: "flex", flexDirection: { xs: "column-reverse", md: "row" } }}>
              <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                <Box
                  {...getRootProps()}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '2px dashed #cccccc',
                    borderRadius: '10px',
                    padding: '20px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    bgcolor: colors.primary[980],
                    height: "100%",
                    marginRight: "10px"
                  }}
                >
                  <input {...getInputProps()} />
                  <UploadFileIcon sx={{ fontSize: "auto", mb: 1 }} />
                  <Typography variant="h6">Subir Documento</Typography>
                  <Typography variant="body2">Arrastra y suelta el archivo aquí, o haz clic para seleccionarlo</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "10px" }}>
                <Box sx={{ textAlign: "center" }}>
                  <ShinyText text="Publicador de Productos desde CSV" disabled={false} speed={3} fontSize="25px" />
                  <Typography variant="h6" sx={{ marginTop: "5px" }}>En esta sección podrás realizar la publicacion de tus productos de manera masiva mediante un archivo CSV</Typography>
                  <Typography variant="h6" sx={{ marginTop: "5px", fontWeight: "600" }}>Si no conoces la estructura del documento CSV te recomiendo revisar el archivo de plantilla descargable</Typography>
                </Box>
                <Grid item xs={12}>
                  <GradientButton style={{ marginTop: "10px" }}>Descargar Plantilla</GradientButton>
                </Grid>
                <Grid item xs={12} sx={{ border: 1, padding: "15px", borderRadius: "15px", marginTop: "20px", textAlign:"center" }}>
                  <Typography variant="h5" sx={{ fontWeight: "600" }}>Recuerda que para habilitar la opción de publicar primero deberas subir un documento y enviarlo para su verificación</Typography>
                  <GradientButton gradient={"red"} style={{ marginTop: "10px", marginBottom: "20px" }}>Verificar CSV</GradientButton>
                  <GradientButton>Publicar</GradientButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CSVPublisher;
