import Box from '@mui/material/Box';
import { Grid, Typography, useTheme } from '@mui/material';
import Filter from '../../components/Shipping/Filter/Filter';
import Cards from '../../components/Shipping/Cards/Cards';
import { cardsData } from '../../mockdata/Mockdata';
import { tokens } from '../../theme';
import ShinyText from '../../components/Global/ShinyText/ShinyText';


const Shipping = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "95%", borderRadius: "20px" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={5}>
                                <ShinyText text="Controla la logística de tus Productos" disabled={false} speed={3} />
                                <Typography
                                    variant="h5"
                                    sx={{
                                        marginRight: "10px",
                                        marginTop: "5px",
                                        color: colors.primary[100]
                                    }}
                                >
                                    En esta sección podrás llevar el control de tus entregas
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={7}
                                sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}
                            >
                                <Filter />
                            </Grid>
                            {cardsData.map((card, index) => (
                                <Grid item xs={12} lg={6} xl={4} key={index}>
                                    <Cards
                                        order={card.order}
                                        title={card.title}
                                        price={card.price}
                                        image={card.image}
                                        track={card.track}
                                        status={card.status}
                                        download={card.download}
                                        tracking={card.tracking}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Shipping;
