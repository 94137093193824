import Box from '@mui/material/Box';
import { Grid, useTheme, Button } from '@mui/material';
import { tokens } from '../../../theme';
import { useState } from 'react';
import GradientButton from '../../Global/GradientButton/GradientButton';
import ShinyText from '../../Global/ShinyText/ShinyText';

const TypeSettings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedType, setSelectedType] = useState('Clásica');

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", bgcolor: colors.primary[400], borderRadius: "15px", padding: "15px" }}>
      <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "100%" }}>
        <Grid container spacing={4}>

          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: "center" }}>
            <ShinyText text="Modifica el tipo de publicación" disabled={false} speed={3} margin="0px 0px 0px" fontSize="25px" />
          </Grid>

          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={() => handleTypeChange('Premium')}
              disabled={selectedType === 'Premium'}
              variant='contained'
              sx={{ width: "100%", height: "50px", backgroundColor: colors.primary[500], color: "white", "&:hover": { backgroundColor: colors.primary[600], } }}
            > Clásica</Button>
          </Grid>

          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={() => handleTypeChange('Clásica')}
              disabled={selectedType === 'Clásica'}
              variant='contained'
              sx={{ width: "100%", height: "50px", backgroundColor: colors.primary[500], color: "white", "&:hover": { backgroundColor: colors.primary[600], } }}
            >Premium</Button>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
            <GradientButton
              onClick={() => console.log(`Guardado: ${selectedType}`)}
              sx={{ width: '100%' }}
            >Guardar</GradientButton>
          </Grid>

        </Grid>
      </Box>
    </Box>
  );
};

export default TypeSettings;