import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import Card from '../../Global/Card/Card';
import { listProducts } from '../../../mockdata/Mockdata';
import ShinyText from '../../Global/ShinyText/ShinyText';
import CustomTextField from '../../Global/CustomTextField/CustomTextField';

const ShopPublisher = () => {
    const [filteredProducts, setFilteredProducts] = useState(listProducts);
    const [categories, setCategories] = useState([]);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        const uniqueCategories = [...new Set(listProducts.map(product => product.category_name))];
        setCategories(uniqueCategories);
    }, []);

    const handleCategoryClick = (category) => {
        if (category === "Todos") {
            setFilteredProducts(listProducts);
        } else {
            const filtered = listProducts.filter(product => product.category_name === category);
            setFilteredProducts(filtered);
        }
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const filteredProductsList = filteredProducts.filter((product) => {
        const lowercasedSearchText = searchText.toLowerCase();
        return (
            (product.title && product.title.toLowerCase().includes(lowercasedSearchText)) ||
            (product.description && product.description.toLowerCase().includes(lowercasedSearchText))
        );
    });

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ padding: { xs: "0px", md: "20px" }, width: "100%", borderRadius: "20px", marginTop: { xs: "25px", md: "0px" } }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: { xs: "center", md: "space-between" }, alignItems: "center", flexDirection: { xs: "column", md: "row" } }}>
                        <ShinyText text="Tienda Voortex" disabled={false} speed={3} />
                        <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: { xs: "100%", md: "auto" } }}>
                            <Box sx={{ display: "flex", alignItems: "center", width: { xs: "100%", md: "auto" }, gap: 3, flexDirection: { xs: "column", md: "row" } }}>
                                <CustomTextField
                                    label="Buscar productos"
                                    value={searchText}
                                    onChange={handleSearchChange}
                                />
                                <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", width: "100%", gap: 4 }}>
                                    <Typography
                                        variant="h7"
                                        sx={{ cursor: "pointer", "&:hover": { color: "#0064FB" } }}
                                        onClick={() => handleCategoryClick("Todos")}
                                    >
                                        Todos
                                    </Typography>
                                    {categories.map((category, index) => (
                                        <Typography
                                            key={index}
                                            variant="h7"
                                            sx={{ cursor: "pointer", "&:hover": { color: "#0064FB" } }}
                                            onClick={() => handleCategoryClick(category)}
                                        >
                                            {category}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    {filteredProductsList.map((product) => (
                        <Grid item xs={6} md={6} lg={4} xl={3} key={product.sku}>
                            <Card
                                key={product.sku}
                                images={product.image}
                                title={product.title}
                                amazonPrice={product.price}
                                rating={"5"}
                                link={product.short_url}
                                buttonText={"Publicar"}
                                actionType="publish"
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default ShopPublisher;
