import Box from '@mui/material/Box';
import { Grid, TextField, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import GradientButton from '../../Global/GradientButton/GradientButton';
import ShinyText from '../../Global/ShinyText/ShinyText';

const UnitPublisher = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px", bgcolor: colors.primary[400], borderRadius: "15px", padding: "15px" }}>
            <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "100%" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ textAlign: "center" }}>
                                    <ShinyText text="Publicador de Productos Unitario" disabled={false} speed={3} fontSize="25px" />
                                    <Typography variant="h5" sx={{ marginRight: "10px", marginTop: "5px" }}>Por favor escribe el código único de tu producto de Amazon</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="filled-basic" label="Código de Producto" variant="filled" fullWidth sx={{ mt: "10px" }} />
                                <Typography variant="h5" sx={{ marginTop: "10px", fontWeight: "600", textAlign: "center" }}>Verifica que tu producto cumpla con las condiciones de MercadoLibre</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <GradientButton gradient={"red"}>Limpiar Buscador</GradientButton>
                            </Grid>
                            <Grid item xs={6}>
                                <GradientButton>Publicar</GradientButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default UnitPublisher;