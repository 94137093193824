import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { tokens } from '../../../theme';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const TotalSales = ({ total, percent }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        display: { xs: "none", md: "none", lg: "flex" },
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.primary[500],
        borderRadius: '15px',
        padding: '16px',
        color: 'white',
        maxWidth: '200px',
        height: "60px",
        marginRight: "20px",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        gap: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          background: colors.primary[400], borderRadius: "50%", padding: "5px"
        }}
      >
        <TrendingUpIcon fontSize="large" sx={{ color: colors.primary[100] }} />
      </Box>
      <Box>
        <Typography variant="h3">{total}</Typography>
        <Typography variant="body2">Utilidad</Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography variant="body1" sx={{ color: '#2ECC71', fontWeight: 'bold' }}>
          {percent}%
        </Typography>
      </Box>
    </Box>
  );
};

export default TotalSales;
